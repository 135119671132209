import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Autocomplete,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const Facet = ({
  facetsData,
  handleChange,
  selectedValues,
  showChips,
  handleClearChip,
  filterMode,
}) => {
  const [options, setOptions] = useState([]);
  const [openCourtOptions, setOpenCourtOptions] = useState(false);
  const [openLawyerOptions, setOpenLawyerOptions] = useState(false);
  const [openJudgeOptions, setOpenJudgeOptions] = useState(false);
  const [lawyerOptions, setLawyerOptions] = useState([]);
  const [judgeOptions, setJudgeOptions] = useState([]);
  const [yearInputError, setYearInputError] = useState(false);
  const [pageInputError, setPageInputError] = useState(false);

  const handleDeleteChip = (category) => {
    handleClearChip(category);
  };

  const handleSelect = (category, value) => {
    handleChange(category, value);
  };

  // const fetchSuggestions = async (input) => {
  //   try {
  //     setLoading(true);
  //     const requestBody = {
  //       count: true,
  //       search: `Court:${input}*`,
  //       select: 'Court',
  //       queryType: 'full',
  //     };
  //     const response = await fetch(
  //       `${config.searchUrl}/indexes/case_laws_indexing_v5/docs/search?api-version=2024-05-01-preview`,
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'api-key': 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv',
  //         },
  //         body: JSON.stringify(requestBody),
  //       }
  //     );
  //     const data = await response.json();
  //     const suggestions = data.value.map((item) => formatCourtName(item.Court));
  //     const uniqueSuggestions = Array.from(new Set(suggestions)).slice(0, 5);
  //     setOptions(uniqueSuggestions);
  //   } catch (error) {
  //     console.error('Error fetching suggestions:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // Function to filter and set the top 5 relevant options
  const filterCourtOptions = (input, options) => {
    // Filter the options based on the input
    const filteredStartsWith = options.filter((option) =>
      option.toLowerCase().startsWith(input.toLowerCase())
    );

    const filteredIncludes = options.filter((option) =>
      option.toLowerCase().includes(input)
    );
    const combinedArray = [
      ...new Set([...filteredStartsWith, ...filteredIncludes]),
    ].slice(0, 5);
    setOptions(combinedArray);
  };

  const filterLawyerOptions = (input, options) => {
    // Filter the options based on the input
    const filteredStartsWith = options.filter((option) =>
      option.toLowerCase().startsWith(input.toLowerCase())
    );

    const filteredIncludes = options.filter((option) =>
      option.toLowerCase().includes(input)
    );
    const combinedArray = [
      ...new Set([...filteredStartsWith, ...filteredIncludes]),
    ].slice(0, 5);
    setLawyerOptions(combinedArray);
  };

  const filterJudgeOptions = (input, options) => {
    // Filter the options based on the input
    const filteredStartsWith = options.filter((option) =>
      option.formatted.toLowerCase().startsWith(input.toLowerCase())
    );

    const filteredIncludes = options.filter((option) =>
      option.formatted.toLowerCase().includes(input)
    );
    const combinedArray = [
      ...new Set([...filteredStartsWith, ...filteredIncludes]),
    ].slice(0, 4);
    setJudgeOptions(combinedArray);
  };

  const handleInputChange = (event, value, fieldOptions) => {
    if (value && value.length > 0) {
      filterCourtOptions(value, fieldOptions);
      setOpenCourtOptions(true);
    } else {
      setOptions([]);
      setOpenCourtOptions(false);
    }
  };

  const handleLawyerInputChange = (event, value, fieldOptions) => {
    if (value && value.length > 0) {
      filterLawyerOptions(value, fieldOptions);
      setOpenLawyerOptions(true);
    } else {
      setLawyerOptions([]);
    }
  };

  const handleJudgeInputChange = (event, value, fieldOptions) => {
    if (value && value.length > 0) {
      filterJudgeOptions(value, fieldOptions);
      setOpenJudgeOptions(true);
    } else {
      setJudgeOptions([]);
    }
  };

  const handleYearInput = (event, category) => {
    const value = event.target.value;

    // Only allow non-decimal numeric input and check for length
    if (/^\d*$/.test(value)) {
      if (value.length === 4 || value === '') {
        // Reset error if the value is valid (4 digits or empty)
        setYearInputError(false);
      } else if (value.length > 0 && (value.length < 4 || value.length > 4)) {
        // Set error if the value is not empty but less than 4 digits
        setYearInputError(true);
      }

      // Update the selected values
      handleSelect(category, value);
    } else {
      // Set error for non-numeric input
      setYearInputError(true);
    }

    if (!value && showChips[category]) {
      handleDeleteChip(category);
    }
  };

  const handlePageInput = (event, category) => {
    const value = event.target.value;
    // Only allow non-decimal numeric input
    if (/^\d+$/.test(value) || value === '') {
      setPageInputError(false);
      // Update the selected values
      handleSelect(category, value);
    } else {
      setPageInputError(true);
    }
    if (!value && showChips[category]) {
      handleDeleteChip(category);
    }
  };

  useEffect(() => {
    setYearInputError(false);
    setPageInputError(false);
  }, [filterMode]);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {filterMode === 'standard' ? (
        <Box sx={{ mb: 1 }}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#333',
              mb: '8px',
              ml: '5px',
            }}
          >
            Journal
          </Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              size="small"
              value={selectedValues['Journal'] || ''}
              onChange={(event, newValue) => handleSelect('Journal', newValue)}
              onInputChange={(e, newValue) => {
                if (!newValue && showChips['Journal']) {
                  handleDeleteChip('Journal');
                }
              }}
              clearIcon={
                selectedValues['Journal'] ? (
                  <ClearIcon fontSize="small" />
                ) : null
              }
              options={
                facetsData?.find((facet) => facet.category === 'Journal')
                  ?.options || []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter Journal"
                  variant="outlined"
                  sx={{
                    width: '95%',
                    ml: '5px',
                    mb: '8px',
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '0.9rem',
                    },
                  }}
                />
              )}
            />
          </FormControl>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#333',
              mb: '8px',
              ml: '5px',
            }}
          >
            Court
          </Typography>
          <Autocomplete
            size="small"
            value={selectedValues['Court'] || ''}
            options={options}
            onChange={(event, newValue) => {
              handleSelect('Court', newValue);
              setOpenCourtOptions(false);
            }}
            onInputChange={(e, newValue) => {
              handleInputChange(
                e,
                newValue,
                facetsData?.find((facet) => facet.category === 'Court')?.options
              );
              if (!newValue && showChips['Court']) {
                handleDeleteChip('Court');
              }
            }}
            open={openCourtOptions && options.length > 0}
            forcePopupIcon={false}
            disableClearable={!selectedValues['Court']}
            clearIcon={
              selectedValues['Court'] ? <ClearIcon fontSize="small" /> : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter Court"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                sx={{
                  width: '95%',
                  ml: '5px',
                  mb: '8px',
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '0.9rem',
                  },
                }}
              />
            )}
          />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#333',
              mb: '8px',
              ml: '5px',
            }}
          >
            Year
          </Typography>
          <TextField
            size="small"
            fullWidth
            placeholder="Enter Year"
            variant="outlined"
            value={selectedValues['Year'] || ''}
            onChange={(e) => handleYearInput(e, 'Year')}
            error={!!yearInputError}
            helperText={
              yearInputError ? 'Please enter a valid 4-digit year' : ''
            }
            sx={{
              width: '95%',
              ml: '5px',
              mb: '8px',
              '& .MuiInputBase-input::placeholder': {
                fontSize: '0.9rem',
              },
            }}
          />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#333',
              mb: '8px',
              ml: '5px',
            }}
          >
            Judge
          </Typography>
          <Autocomplete
            size="small"
            value={selectedValues['Judge'] || ''}
            onChange={(event, newValue) => {
              handleSelect('Judge', newValue ? newValue : '');
              setOpenJudgeOptions(false);
            }}
            onInputChange={(e, newValue) => {
              handleJudgeInputChange(
                e,
                newValue,
                facetsData?.find((facet) => facet.category === 'Judge')?.options
              );
              if (!newValue && showChips['Judge']) {
                handleDeleteChip('Judge');
              }
            }}
            clearIcon={
              selectedValues['Judge'] ? <ClearIcon fontSize="small" /> : null
            }
            open={openJudgeOptions && judgeOptions.length > 0}
            forcePopupIcon={false}
            options={judgeOptions}
            isOptionEqualToValue={(option, value) =>
              option.formatted === value?.formatted
            }
            getOptionLabel={(option) => option.formatted || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter Judge"
                variant="outlined"
                sx={{
                  width: '95%',
                  ml: '5px',
                  mb: '8px',
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '0.9rem',
                  },
                }}
              />
            )}
          />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#333',
              mb: '8px',
              ml: '5px',
            }}
          >
            Lawyer
          </Typography>
          <Autocomplete
            size="small"
            value={selectedValues['Lawyer'] || ''}
            options={lawyerOptions}
            onChange={(event, newValue) => {
              handleSelect('Lawyer', newValue);
              setOpenLawyerOptions(false);
            }}
            onInputChange={(e, newValue) => {
              handleLawyerInputChange(
                e,
                newValue,
                facetsData?.find((facet) => facet.category === 'Lawyer')
                  ?.options
              );
              if (!newValue && showChips['Lawyer']) {
                handleDeleteChip('Lawyer');
              }
            }}
            open={openLawyerOptions && lawyerOptions.length > 0}
            forcePopupIcon={false}
            clearIcon={
              selectedValues['Lawyer'] ? <ClearIcon fontSize="small" /> : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter Lawyer"
                variant="outlined"
                sx={{
                  width: '95%',
                  ml: '5px',
                  mb: '8px',
                  '& .MuiInputBase-input::placeholder': {
                    fontSize: '0.9rem',
                  },
                }}
              />
            )}
          />
        </Box>
      ) : (
        <Box sx={{ mb: 1 }}>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#333',
              mb: '8px',
              ml: '5px',
            }}
          >
            Journal
          </Typography>
          <FormControl fullWidth variant="outlined">
            <Autocomplete
              size="small"
              value={selectedValues['Journal'] || ''}
              onChange={(event, newValue) => handleSelect('Journal', newValue)}
              onInputChange={(e, newValue) => {
                if (!newValue && showChips['Journal']) {
                  handleDeleteChip('Journal');
                }
              }}
              clearIcon={
                selectedValues['Journal'] ? (
                  <ClearIcon fontSize="small" />
                ) : null
              }
              options={
                facetsData?.find((facet) => facet.category === 'Journal')
                  ?.options || []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter Journal"
                  variant="outlined"
                  sx={{
                    width: '95%',
                    ml: '5px',
                    mb: '8px',
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '0.9rem',
                    },
                  }}
                />
              )}
            />
          </FormControl>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#333',
              mb: '8px',
              ml: '5px',
            }}
          >
            Year
          </Typography>
          <TextField
            size="small"
            fullWidth
            placeholder="Enter Year"
            variant="outlined"
            value={selectedValues['Year'] || ''}
            onChange={(e) => handleYearInput(e, 'Year')}
            error={!!yearInputError}
            helperText={
              yearInputError ? 'Please enter a valid 4-digit year' : ''
            }
            sx={{
              width: '95%',
              ml: '5px',
              mb: '8px',
              '& .MuiInputBase-input::placeholder': {
                fontSize: '0.9rem',
              },
            }}
          />
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 600,
              color: '#333',
              mb: '8px',
              ml: '5px',
            }}
          >
            Page
          </Typography>
          <TextField
            size="small"
            fullWidth
            placeholder="Enter Page Number"
            variant="outlined"
            value={selectedValues['Page'] || ''}
            onChange={(e) => handlePageInput(e, 'Page')}
            error={!!pageInputError}
            helperText={
              pageInputError ? 'Please enter a valid page number' : ''
            }
            sx={{
              width: '95%',
              ml: '5px',
              mb: '8px',
              '& .MuiInputBase-input::placeholder': {
                fontSize: '0.9rem',
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Facet;
