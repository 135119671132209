import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Divider,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { MuiTelInput } from "mui-tel-input";
import { signUp } from "../actions/userActions";
import PersonIcon from "@mui/icons-material/Person";
import { display } from "@mui/system";
import EmailIcon from "@mui/icons-material/Email";
import WorkIcon from "@mui/icons-material/Work";
import LockIcon from "@mui/icons-material/Lock";

const SignUpModal = ({ open, handleClose }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cnic, setCnic] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastText, setToastText] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");
  const dispatch = useDispatch();

  const userSignup = useSelector((state) => state.userSignup);
  const { message, error } = userSignup;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !designation ||
      !username ||
      !password ||
      !cnic ||
      !address ||
      !company ||
      !confirmPassword
    ) {
      setToastSeverity("error");
      setToastText("Please fill in all fields");
      setOpenToast(true);
      return;
    }

    const nameRegex = /^[A-Za-z]+$/;
    if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
      setToastSeverity("error");
      setToastText(
        "First and Last name should only contain alphabetic characters"
      );
      setOpenToast(true);
      return;
    }

    const cnicRegex = /^\d{13}$/;
    if (!cnicRegex.test(cnic)) {
      setToastSeverity("error");
      setToastText("CNIC must be exactly 13 numeric characters");
      setOpenToast(true);
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setToastSeverity("error");
      setToastText("Please enter a valid email address");
      setOpenToast(true);
      return;
    }

    const passwordRegex = /^(?=.*\d).{8,}$/;
    if (!passwordRegex.test(password)) {
      setToastSeverity("warning");
      setToastText(
        "Password must be at least 8 characters long and contain at least one numeric character"
      );
      setOpenToast(true);
      return;
    }

    if (password !== confirmPassword) {
      setToastSeverity("warning");
      setToastText("Passwords do not match");
      setOpenToast(true);
      return;
    }

    setIsLoading(true);
    try {
      await dispatch(
        signUp(
          firstName,
          lastName,
          email,
          phoneNumber,
          designation,
          username,
          password,
          cnic,
          company,
          address
        )
      );
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenToast(false);
  };

  useEffect(() => {
    if (message) {
      if (
        message === "Username already exists." ||
        message === "Email already exists." ||
        message === "Phone number already exists."
      ) {
        setToastSeverity("info");
      } else {
        setToastSeverity("success");
        handleClose();
      }
      setToastText(message);
      setOpenToast(true);
      dispatch({ type: "USER_SIGNUP_RESET" });
    }
    if (error) {
      setToastSeverity("error");
      setToastText(error);
      setOpenToast(true);
    }
  }, [message, error, handleClose, dispatch]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "#003D78",
            fontWeight: "bold",
          }}
        >
          Sign Up
        </DialogTitle>
        <Divider />

        <DialogContent>
          {/* Personal Information Section */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <PersonIcon sx={{ mt: 0.5, mr: 1 }} />
            <Typography variant="h6">Personal Information</Typography>
          </Box>
          <Divider sx={{ bgcolor: "#003D78" }} />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First Name"
                placeholder="Enter your first name"
                variant="outlined"
                margin="normal"
                value={firstName}
                size="small"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Last Name"
                placeholder="Enter your last name"
                variant="outlined"
                margin="normal"
                size="small"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="CNIC Number"
                placeholder="Enter your CNIC number"
                variant="outlined"
                margin="normal"
                size="small"
                value={cnic}
                onChange={(e) => setCnic(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* Contact Information Section */}
          <Box sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
            <EmailIcon sx={{ mt: 0.5, mr: 1 }} />
            <Typography variant="h6">Contact Information</Typography>
          </Box>
          <Divider sx={{ bgcolor: "#003D78" }} />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                placeholder="Enter your email"
                variant="outlined"
                margin="normal"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MuiTelInput
                defaultCountry="PK"
                value={phoneNumber}
                onChange={setPhoneNumber}
                fullWidth
                label="Phone Number"
                variant="outlined"
                margin="normal"
                size="small"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                placeholder="Enter your address"
                variant="outlined"
                size="small"
                margin="normal"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* Professional Information Section */}
          <Box sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
            <WorkIcon sx={{ mt: 0.5, mr: 1 }} />
            <Typography variant="h6">Professional Information</Typography>
          </Box>
          <Divider sx={{ bgcolor: "#003D78" }} />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Company/Organization"
                placeholder="Enter your company or organization"
                variant="outlined"
                margin="normal"
                size="small"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Designation"
                placeholder="Enter your designation"
                variant="outlined"
                size="small"
                margin="normal"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </Grid>
          </Grid>

          {/* Account Information Section */}
          <Box sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
            <LockIcon sx={{ mt: 0.5, mr: 1 }} />
            <Typography variant="h6">Account Information</Typography>
          </Box>
          <Divider sx={{ bgcolor: "#003D78" }} />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Username"
                placeholder="Enter your username"
                variant="outlined"
                margin="normal"
                size="small"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Password"
                placeholder="Enter your password"
                variant="outlined"
                margin="normal"
                type="password"
                size="small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                placeholder="Confirm your password"
                variant="outlined"
                margin="normal"
                type="password"
                size="small"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ mr: 2 }}>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ backgroundColor: "#003D78" }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={22} /> : "Sign Up"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToast}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          sx={{ width: "100%" }}
          severity={toastSeverity}
          variant="filled"
          onClose={handleCloseSnackbar}
        >
          {toastText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SignUpModal;
