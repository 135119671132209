import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  IconButton,
  Switch,
  FormControlLabel,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  advancedSearchResults,
  searchResults,
} from '../../actions/searchActions';
import { KeyboardVoice } from '@mui/icons-material';

const CaseLawSearchBar = ({
  resultsCount,
  updateResultsCount,
  sortBy,
  updateSortBy,
  searchMode,
  updateSearchMode,
  searchKeyword,
  setSearchKeyword,
  updateCurrentPage,
  setIsNewCaseLawSearch,
}) => {
  const dispatch = useDispatch();

  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSpeechSupported, setIsSpeechSupported] = useState(true);
  const [placeholder, setPlaceholder] = useState('Search for case law...');
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

  const silenceTimeoutRef = useRef(null);
  const finalTranscriptRef = useRef('');

  const handleSearch = (keyword = searchKeyword) => {
    if (!keyword) return;
    if (isAdvancedSearch) {
      dispatch(advancedSearchResults(keyword));
    } else {
      dispatch(searchResults(keyword, 0, resultsCount, searchMode, sortBy));
    }
    setIsNewCaseLawSearch(true);
    updateCurrentPage(1);
    setIsSearchClicked(true);
  };

  const handleSwitchChange = (event) => {
    setIsAdvancedSearch(event.target.checked);
    if (event.target.checked) {
      updateSearchMode('semantic');
    } else {
      updateSearchMode('keyword');
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const getSpeechRecognition = () => {
    return window.SpeechRecognition || window.webkitSpeechRecognition || null;
  };

  const SpeechRecognitionConstructor = getSpeechRecognition();

  let recognition;
  if (SpeechRecognitionConstructor) {
    recognition = new SpeechRecognitionConstructor();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = 'en-US';

    recognition.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join('');
      setSearchKeyword(transcript);
      finalTranscriptRef.current = transcript;
      resetSilenceTimer();
    };

    recognition.onerror = (event) => {
      console.error(event.error);
      stopListening();
    };

    recognition.onend = () => {
      setIsListening(false);
      setIsProcessing(false);
      setPlaceholder('Search for case law...');
      clearSilenceTimer();
      if (finalTranscriptRef.current) {
        handleSearch(finalTranscriptRef.current);
      }
    };
  }

  const startListening = () => {
    if (recognition) {
      setIsListening(true);
      setPlaceholder('Speak...');
      recognition.start();
      startSilenceTimer();
    }
  };

  const stopListening = () => {
    if (recognition) {
      setIsProcessing(true);
      recognition.stop();
    }
  };

  const toggleListening = () => {
    if (!isSpeechSupported) {
      alert('Voice recognition is not supported on this browser.');
      return;
    }

    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startSilenceTimer = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      stopListening();
    }, 3000);
  };

  const resetSilenceTimer = () => {
    clearTimeout(silenceTimeoutRef.current);
    startSilenceTimer();
  };

  const clearSilenceTimer = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  useEffect(() => {
    const isSupported =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    setIsSpeechSupported(!!isSupported);
  }, []);

  useEffect(() => {
    if (searchKeyword === '' && isSearchClicked) {
      dispatch(
        searchResults(searchKeyword, 0, resultsCount, searchMode, sortBy)
      );
      setIsSearchClicked(false);
      updateCurrentPage(1);
    }
  }, [
    dispatch,
    resultsCount,
    sortBy,
    searchKeyword,
    searchMode,
    isSearchClicked,
    updateCurrentPage,
  ]);

  return (
    <Box
      sx={{
        // backgroundColor: "#ffffff",
        padding: '15px 15px 8px 15px',
        borderRadius: '8px',
        //mb: '10px',
        // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        top: 0,
        backgroundColor: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '15px',
          width: '95%',
          margin: '0 auto',
        }}
      >
        <TextField
          placeholder={placeholder}
          variant="outlined"
          //fullWidth
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
          onKeyDown={handleEnter}
          InputProps={{
            sx: {
              borderRadius: 8,
              p: 0,
              backgroundColor: '#ffffff',
              borderRadius: '20px',
            },
            startAdornment: (
              <IconButton sx={{ color: '#303f9f' }}>
                <SearchIcon />
              </IconButton>
            ),
            endAdornment: (
              <IconButton
                sx={{
                  color: isListening ? '#FF4081' : '#303f9f',
                  animation: isListening ? 'pulse 1.5s infinite' : 'none',
                }}
                variant="contained"
                onClick={toggleListening}
                disabled={!isSpeechSupported || isProcessing}
              >
                <KeyboardVoice />
              </IconButton>
            ),
          }}
          sx={{
            flexGrow: 1,
            marginRight: '10px',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#ccc',
              },
              '&:hover fieldset': {
                borderColor: '#00509e',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#00509e',
              },
            },
            '& input': {
              padding: '10px 10px',
              fontSize: '0.875rem',
            },
          }}
        />
        <FormControl variant="outlined" sx={{ width: 180 }}>
          <FormControlLabel
            control={
              <Switch
                checked={isAdvancedSearch}
                onChange={handleSwitchChange}
              />
            }
            label="Advanced Search"
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: '0.875rem',
              },
            }}
          />
        </FormControl>
        {/* <FormControl
          variant="outlined"
          sx={{ minWidth: 160, marginRight: '10px' }}
        >
          <Select
            value={searchMode}
            onChange={(e) => updateSearchMode(e.target.value)}
            displayEmpty
            sx={{
              fontSize: '0.875rem',
              height: '40px',
              '& .MuiSelect-select': {
                padding: '10px 10px',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <MenuItem value="keyword">Keyword Search</MenuItem>
            <MenuItem value="semantic">Advanced Search</MenuItem>
          </Select>
        </FormControl> */}
        {/* <Button
          variant="contained"
          color="primary"
          onClick={() => handleSearch()}
          startIcon={<SearchIcon />}
          sx={{
            backgroundColor: "#00509e",
            "&:hover": {
              backgroundColor: "#003366",
            },
            padding: "8px 20px",
            fontSize: "0.875rem",
            textTransform: "none",
          }}
        >
          Search
        </Button> */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* <InputLabel
          sx={{
            marginRight: '10px',
            fontSize: '1rem',
            color: 'black',
          }}
          htmlFor="sortOptions"
        >
          Sort by:
        </InputLabel>
        <FormControl
          variant="outlined"
          sx={{ minWidth: 170, marginRight: '20px' }}
        >
          <Select
            id="sortOptions"
            value={sortBy}
            onChange={(e) => updateSortBy(e.target.value)}
            sx={{
              fontSize: '0.875rem',
              height: '40px',
              '& .MuiSelect-select': {
                padding: '10px 10px',
                display: 'flex',
                alignItems: 'center',
              },
            }}
          >
            <MenuItem value="Most Relevant">Most Relevant First</MenuItem>
            <MenuItem value="Newest">Year: Newest First</MenuItem>
            <MenuItem value="Oldest">Year: Oldest First</MenuItem>
          </Select>
        </FormControl> */}
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputLabel
            sx={{
              marginRight: "10px",
              fontSize: "1rem",
              color: "black",
            }}
            htmlFor="resultsCount"
          >
            Show:
          </InputLabel>
          <FormControl variant="outlined" sx={{ minWidth: 110 }}>
            <Select
              id="resultsCount"
              value={resultsCount}
              onChange={(e) => updateResultsCount(e.target.value)}
              sx={{
                fontSize: "0.875rem",
                height: "40px",
                "& .MuiSelect-select": {
                  padding: "10px 10px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <MenuItem value={10}>10 results</MenuItem>
              <MenuItem value={20}>20 results</MenuItem>
              <MenuItem value={50}>50 results</MenuItem>
            </Select>
          </FormControl>
        </Box> */}
      </Box>
    </Box>
  );
};

export default CaseLawSearchBar;
