import config from '../config/api';

export const getFacets = () => async (dispatch) => {
  try {
    dispatch({
      type: 'SEARCH_FACETS_REQUEST',
    });
    const endpoint =
      'https://facets-retrieval.azurewebsites.net/api/facets/lawcases';
    const requestBody = {
      facets: [
        'Court,count:0,sort:value',
        'Year,count:0,sort:value',
        'Section,count:0,sort:value',
        'Lawyer_Name,count:0,sort:count',
        'Judge_Name,count:0,sort:count',
      ],
      query: '*',
      filter: '',
      mode: 'keyword',
      sort_by: '',
      top: 10,
      skip: 0,
      count: true,
    };
    const response = await fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      //body: JSON.stringify(requestBody),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: 'SEARCH_FACETS_SUCCESS',
        payload: responseData,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: 'SEARCH_FACETS_FAIL',
      payload: 'Failed to fetch facets.',
    });
  }
};

export const searchResults =
  (userQuery, skip = 0, top = 10, mode = 'keyword', sortBy = 'Most Relevant') =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'GET_SEARCH_RESULTS_REQUEST',
      });
      const endpoint = `${config.searchUrl}/lawcases`;
      const requestBody = {
        query: userQuery,
        mode: mode,
        top: top,
        skip: skip,
        filter: '',
        sort_by: sortBy,
        //searchMode: 'all',
      };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      console.log(response);
      if (response.ok) {
        const responseData = await response.json();
        dispatch({
          type: 'GET_SEARCH_RESULTS_SUCCESS',
          payload: responseData,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: 'GET_SEARCH_RESULTS_FAIL',
        payload: 'Failed to fetch chat titles.',
      });
    }
  };

export const advancedSearchResults = (userQuery) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_SEARCH_RESULTS_REQUEST',
    });
    const endpoint = `${config.apiUrl}/case_search`;
    const requestBody = {
      query: userQuery,
    };
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    console.log(response);
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: 'GET_SEARCH_RESULTS_SUCCESS',
        payload: responseData,
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: 'GET_SEARCH_RESULTS_FAIL',
      payload: 'Failed to fetch chat titles.',
    });
  }
};

export const findCaseFromAzureAiSearch =
  (citation, court) => async (dispatch) => {
    try {
      dispatch({
        type: 'CASE_DETAIL_REQUEST',
      });
      const endpoint = `https://rg-law-gpt-ai-search-v3.search.windows.net/indexes/case_laws_indexing_v5/docs/search?api-version=2024-05-01-preview`;
      const apiKey = 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv';
      const requestBody = {
        searchMode: 'any',
        queryType: 'simple',
        filter: `Case_Id eq '${citation} ${court}'`,
      };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey,
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch({
          type: 'CASE_DETAIL_SUCCESS',
          payload: responseData,
        });
      }
    } catch (error) {
      dispatch({
        type: 'CASE_DETAIL_RESET',
        payload: 'Failed to fetch case details.',
      });
    }
  };

// export const findCaseSummary = (citation) => async (dispatch) => {
//   try {
//     dispatch({
//       type: 'CASE_DETAIL_REQUEST',
//     });
//     const endpoint = `${config.backendUrl}/getCaseDetails`;
//     const requestBody = {
//       citation: citation,
//     };
//     const response = await fetch(endpoint, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'api-key': apiKey,
//       },
//       body: JSON.stringify(requestBody),
//     });
//     if (response.ok) {
//       const responseData = await response.json();
//       dispatch({
//         type: 'CASE_DETAIL_SUCCESS',
//         payload: responseData,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: 'CASE_DETAIL_RESET',
//       payload: 'Failed to fetch case details.',
//     });
//   }
// };

export const findCaseSummary = (citation) => async (dispatch) => {
  try {
    dispatch({
      type: 'CASE_DETAILS_FROM_COSMOS_REQUEST',
    });
    const endpoint = `${config.backendUrl}/getCaseDetails`;
    const requestBody = {
      citation: citation,
    };
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (response.ok) {
      const responseData = await response.json();
      dispatch({
        type: 'CASE_DETAILS_FROM_COSMOS_SUCCESS',
        payload: responseData,
      });
    }
  } catch (error) {
    dispatch({
      type: 'CASE_DETAILS_FROM_COSMOS_FAIL',
      payload: 'Failed to fetch case details.',
    });
  }
};

export const applyFilters =
  (searchQuery, filters, skip, top, mode, sortBy) => async (dispatch) => {
    try {
      dispatch({
        type: 'GET_SEARCH_RESULTS_REQUEST',
      });

      const formatFilterString = (filterObject) => {
        const mapping = {
          Journal: 'Section',
          Court: 'Court',
          Year: 'Year',
          Judge: 'Judge_Name',
          Lawyer: 'Lawyer_Name',
          Citation_Name: 'Citation_Name',
        };

        const formattedFilters = Object.keys(filters).reduce((acc, key) => {
          const newKey = mapping[key];
          const value = filters[key];
          if (Array.isArray(value) && value.length > 0) {
            const conditions = value
              .map((v) => `${newKey} eq '${v}'`)
              .join(' or ');
            acc.push(`(${conditions})`);
          } else if (newKey === 'Lawyer_Name' || newKey === 'Judge_Name') {
            if (value) {
              acc.push(`(${newKey}/any(t: t eq '${value}'))`);
            }
          } else if (!Array.isArray(value) && value) {
            acc.push(`(${newKey} eq '${value}')`);
          }

          return acc;
        }, []);
        return formattedFilters.join(' and ');
      };

      const endpoint = `${config.searchUrl}/lawcases`;
      const requestBody = {
        count: true,
        skip: skip,
        top: top,
        facets: [],
        mode: mode,
        sort_by: sortBy,
        filter: formatFilterString(filters),
        query: searchQuery,
      };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        const responseData = await response.json();
        dispatch({
          type: 'GET_SEARCH_RESULTS_SUCCESS',
          payload: responseData,
        });
      }
    } catch (error) {
      dispatch({
        type: 'GET_SEARCH_RESULTS_FAIL',
        payload: 'Failed to fetch chat titles.',
      });
    }
  };
