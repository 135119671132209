import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  FormControlLabel,
  Select,
  Switch,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import { KeyboardVoice } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

import {
  searchSections,
  statuteSearchResults,
} from '../actions/statuteSearchActions';
import { AddCircleOutline } from '@mui/icons-material';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';

const deleteCaseUrl =
  'https://rg-law-gpt-be-auth2.azurewebsites.net/api/deleteCaseOrStatuteFromGroup';

const StatuteSearch = ({
  searchMode,
  resultsCount,
  updateSearchMode,
  updateResultsCount,
  currentPage,
  updateCurrentPage,
  statuteSearchValue,
  updateStatuteSearchValue,
  selectedYear,
  selectedLetter,
}) => {
  const [expandedResult, setExpandedResult] = useState(null);
  const [expandedSubItems, setExpandedSubItems] = useState({});
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [selectedStatute, setSelectedStatute] = useState(null);
  const [bookmarkGroups, setBookmarkGroups] = useState([]);
  const [bookmarkGroupName, setBookmarkGroupName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [statuteNote, setStatuteNote] = useState('');
  const [bookmarkedStatuteIds, setBookmarkedStatuteIds] = useState(new Set());
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [placeholder, setPlaceholder] = useState('Search for Statutes...');
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const silenceTimeoutRef = useRef(null);
  const finalTranscriptRef = useRef('');

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const statuteSearch = useSelector((state) => state.statuteSearch);
  const { loading: statuteLoading, results } = statuteSearch;

  const searchResultsCount = useSelector((state) => state.searchResultsCount);
  const { count } = searchResultsCount;

  const isStatuteFilterApplied = useSelector(
    (state) => state.isStatuteFilterApplied
  );
  const { isStatuteSearchFilterApplied } = isStatuteFilterApplied;

  const statuteSections = useSelector((state) => state.statuteSections);
  const { loading, sections } = statuteSections;

  const totalResults = results?.count || 0;

  const handlePrimarySearchValueChange = (e) => {
    updateStatuteSearchValue(e.target.value);
  };

  const formatStatuteTitleHtml = (htmlString) => {
    if (!htmlString) return '';

    // Parse the HTML string into a DOM element
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Function to apply title case to each word
    const titleCaseText = (text) => {
      return text
        .toLowerCase()
        .split(' ')
        .map((word) => {
          // Preserve acronyms (fully uppercase words)
          if (word.length === 1 || word === word.toUpperCase()) {
            return word;
          }
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    };

    // Function to traverse the DOM and apply title case to text nodes only
    const traverseAndTitleCase = (node) => {
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.TEXT_NODE) {
          // Only apply title case to the text content, not HTML tags
          child.textContent = titleCaseText(child.textContent);
        } else if (child.nodeType === Node.ELEMENT_NODE) {
          // Recurse through element nodes
          traverseAndTitleCase(child);
        }
      });
    };

    // Start traversal and title case application
    traverseAndTitleCase(doc.body);

    // Return the modified HTML string
    return doc.body.innerHTML;
  };

  const formatStatuteTitle = (title) => {
    if (!title) return;
    const titleCaseTitle = title
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return titleCaseTitle;
  };

  const handlePageChange = (event, value) => {
    updateCurrentPage(value);
    dispatch({
      type: 'STATUTE_PAGINATION_SUCCESS',
      payload: { pageNumber: value, resultsCount },
    });
  };

  const handleResultToggle = (statuteName) => {
    if (expandedResult === statuteName) {
      setExpandedResult(null);
      setExpandedSubItems((prev) => ({
        ...prev,
        [statuteName]: {},
      }));
    } else {
      setExpandedResult(statuteName);
      setExpandedSubItems((prev) => ({
        ...prev,
        [statuteName]: {},
      }));
    }
  };

  const handleSubItemToggle = (statuteName, index, event) => {
    event.stopPropagation();
    setExpandedSubItems((prevState) => ({
      ...prevState,
      [statuteName]: {
        ...prevState[statuteName],
        [index]: !prevState[statuteName]?.[index],
      },
    }));
  };

  const handleBookmarkClick = (statute) => {
    setSelectedStatute(statute);
    fetchBookmarkGroups();
    setOpenBookmarkModal(true);
  };

  const fetchBookmarkGroups = async () => {
    try {
      const userId = userInfo._id;
      const response = await fetch(
        `https://rg-law-gpt-be-auth2.azurewebsites.net/api/getUserBookmarkGroups?userId=${userId}`
      );
      const data = await response.json();
      if (response.ok) {
        // Extract all statute IDs from the statutes array in each group document
        const allStatuteIds = data.flatMap((group) =>
          group.statutes.map((statute) => statute.id)
        );

        // Set the bookmark groups and statute IDs
        setBookmarkGroups(data);
        setBookmarkedStatuteIds(new Set(allStatuteIds)); // Store IDs in a Set for fast lookup
      } else {
        throw new Error(data.message || 'Failed to fetch bookmark groups.');
      }
    } catch (error) {
      console.error('Error fetching bookmark groups:', error);
    }
  };

  const handleCreateBookmarkGroup = async () => {
    if (!bookmarkGroupName) return;
    try {
      const userId = userInfo._id;
      const response = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/createBookmarkGroup',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            groupName: bookmarkGroupName,
            statuteId: selectedStatute.id,
            statuteNote,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Bookmark group created successfully!',
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.id])
        );

        setOpenBookmarkModal(false);
        setBookmarkGroupName('');
        setStatuteNote('');
      } else {
        throw new Error(data.message || 'Failed to create bookmark group.');
      }
    } catch (error) {
      console.error('Error creating bookmark group:', error);
    }
  };

  const handleAddStatuteToGroup = async () => {
    if (!selectedGroup) return;
    try {
      const userId = userInfo._id;
      const response = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/addStatuteToGroup',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            groupId: selectedGroup,
            statuteId: selectedStatute.id,
            statuteNote, // Include notes when adding to a group
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Statute added to the selected group!',
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.id])
        );

        setOpenBookmarkModal(false);
        setStatuteNote(''); // Clear notes after submission
      } else {
        throw new Error(data.message || 'Failed to add statute to group.');
      }
    } catch (error) {
      console.error('Error adding statute to group:', error);
    }
  };

  const findGroupIdByStatuteId = (documents, statuteId) => {
    const group = documents.find((doc) =>
      doc.statutes.some((statuteItem) => statuteItem.id === statuteId)
    );

    return group ? group._id : null;
  };

  const handleUnBookmarkStatute = async (statuteId) => {
    try {
      fetchBookmarkGroups();
      const groupId = findGroupIdByStatuteId(bookmarkGroups, statuteId);
      const response = await fetch(deleteCaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the statute is bookmarked
          statuteId, // Statute ID to delete
        }),
      });

      if (response.ok) {
        // Update the state by removing the statute ID from bookmarked statute IDs
        setBookmarkedStatuteIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(statuteId);
          return updatedIds;
        });
      } else {
        console.error('Error deleting bookmarked statute');
      }
    } catch (error) {
      console.error('Error deleting bookmarked statute:', error);
    }
  };

  const handleStatuteNameClick = (name) => {
    // Remove HTML tags from the name using regex
    const cleanedName = name.replace(/<\/?[^>]+(>|$)/g, '');

    // Dispatch the action with the cleaned name
    dispatch(searchSections('', cleanedName, searchMode));
  };

  const handleSearchClick = (keyword = statuteSearchValue) => {
    if (!keyword) return;
    setIsSearchClicked(true);
    dispatch(
      statuteSearchResults(
        statuteSearchValue,
        searchMode,
        resultsCount,
        (currentPage - 1) * resultsCount
      )
    );
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      if (!statuteSearchValue) {
        return;
      } else {
        setIsSearchClicked(true);
        dispatch(
          statuteSearchResults(
            statuteSearchValue,
            searchMode,
            resultsCount,
            (currentPage - 1) * resultsCount
          )
        );
      }
    }
  };

  useEffect(() => {
    if (!isStatuteSearchFilterApplied && currentPage) {
      dispatch(
        statuteSearchResults(
          statuteSearchValue,
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount
        )
      );
    }
  }, [currentPage, resultsCount]);

  // useEffect(() => {
  //   if (results && results.count) {
  //     setCurrentPage(1);
  //     dispatch({
  //       type: 'SEARCH_RESULTS_COUNT_SUCCESS',
  //       payload: results.count,
  //     });
  //   }
  // }, [dispatch, results]);

  useEffect(() => {
    dispatch(
      statuteSearchResults(
        statuteSearchValue,
        searchMode,
        resultsCount,
        (currentPage - 1) * resultsCount
      )
    );
  }, []);

  useEffect(() => {
    if (!statuteSearchValue && isSearchClicked) {
      dispatch(
        statuteSearchResults(
          '',
          searchMode,
          resultsCount,
          (currentPage - 1) * resultsCount
        )
      );
      setIsSearchClicked(false);
    }
  }, [
    dispatch,
    statuteSearchValue,
    isSearchClicked,
    searchMode,
    resultsCount,
    currentPage,
  ]);

  useEffect(() => {
    // Fetch bookmark groups when the component mounts or when userInfo changes
    if (userInfo._id) {
      fetchBookmarkGroups();
    }
  }, [userInfo]);

  const recognition = new window.webkitSpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
  recognition.lang = 'en-US';

  recognition.onresult = (event) => {
    const transcript = Array.from(event.results)
      .map((result) => result[0].transcript)
      .join('');
    updateStatuteSearchValue(transcript);
    finalTranscriptRef.current = transcript;
    resetSilenceTimer();
  };

  recognition.onerror = (event) => {
    console.error(event.error);
    stopListening();
  };

  const startListening = () => {
    setIsListening(true);
    setPlaceholder('Speak...');
    recognition.start();

    startSilenceTimer();
  };

  const stopListening = () => {
    setIsProcessing(true);
    recognition.stop();
  };

  recognition.onend = () => {
    setIsListening(false);
    setIsProcessing(false);
    setPlaceholder('Search for case law...');
    clearSilenceTimer();
    if (finalTranscriptRef.current) {
      handleSearchClick(finalTranscriptRef.current);
    }
  };

  const toggleListening = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startSilenceTimer = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      stopListening();
    }, 3000);
  };

  const resetSilenceTimer = () => {
    clearTimeout(silenceTimeoutRef.current);
    startSilenceTimer();
  };

  const clearSilenceTimer = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          boxSizing: 'border-box',
          flexDirection: 'column',
          height: 'calc(100vh - 50px)',
          flex: 5,
          p: 2,
          paddingBottom: '4px',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Box
          sx={{
            // backgroundColor: "#ffffff",
            padding: '15px',
            borderRadius: '8px',
            // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '15px',
              width: '95%',
              margin: '0 auto',
            }}
          >
            <TextField
              variant="outlined"
              autoFocus
              placeholder={placeholder}
              value={statuteSearchValue}
              onChange={handlePrimarySearchValueChange}
              onKeyDown={handleEnter}
              fullWidth
              sx={{
                backgroundColor: '#ffffff',
                borderRadius: '20px',
                marginRight: '10px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#ccc',
                  },
                  '&:hover fieldset': {
                    borderColor: '#00509e',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#00509e',
                  },
                },
                '& input': {
                  padding: '10px 10px',
                  fontSize: '0.875rem',
                },
              }}
              InputProps={{
                sx: { borderRadius: 8, p: 0 },
                startAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    sx={{
                      color: isListening ? '#FF4081' : '#303f9f',
                      animation: isListening ? 'pulse 1.5s infinite' : 'none',
                    }}
                    variant="contained"
                    onClick={toggleListening}
                    disabled={isProcessing}
                  >
                    <KeyboardVoice />
                  </IconButton>
                ),
              }}
            />
            {/* <FormControl variant="outlined" sx={{ width: 200 }}>
              <FormControlLabel
                control={
                  <Switch
                  // checked={isAdvancedSearch}
                  // onChange={handleSwitchChange}
                  />
                }
                label="Advanced Search"
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "0.875rem",
                  },
                }}
              />
            </FormControl> */}

            {/* <FormControl
              variant="outlined"
              sx={{ minWidth: 160, marginRight: '10px' }}
            >
              <Select
                value={searchMode}
                onChange={(e) => updateSearchMode(e.target.value)}
                displayEmpty
                sx={{
                  fontSize: '0.875rem',
                  height: '40px',
                  '& .MuiSelect-select': {
                    padding: '10px 10px',
                    display: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                <MenuItem value="keyword">Keyword Search</MenuItem>
                <MenuItem value="semantic">Advanced Search</MenuItem>
              </Select>
            </FormControl> */}
            {/* <Button
              onClick={() => handleSearchClick()}
              startIcon={<SearchIcon />}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#00509e",
                "&:hover": {
                  backgroundColor: "#003366",
                },
                padding: "8px 20px",
                fontSize: "0.875rem",
                textTransform: "none",
              }}
            >
              Search
            </Button> */}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputLabel
                sx={{
                  marginRight: "10px",
                  fontSize: "1rem",
                  color: "black",
                }}
                htmlFor="resultsCount"
              >
                Show:
              </InputLabel>
              <FormControl
                variant="outlined"
                sx={{ width: 100, boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              >
                <Select
                  id="resultsCount"
                  value={resultsCount}
                  onChange={(e) => updateResultsCount(e.target.value)}
                  sx={{
                    fontSize: "0.875rem",
                    height: "40px",
                    "& .MuiSelect-select": {
                      padding: "10px 10px",
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                >
                  <MenuItem value={10}>10 results</MenuItem>
                  <MenuItem value={20}>20 results</MenuItem>
                  <MenuItem value={50}>50 results</MenuItem>
                  <MenuItem value={100}>100 results</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
          </Box>
        </Box>
        {statuteLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <CircularProgress size={18} />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6" sx={{ mt: 1, fontWeight: 'bold' }}>
                <span style={{ color: '#d2042d', fontWeight: 'bold' }}>
                  {totalResults < 1000
                    ? totalResults.toLocaleString()
                    : `${(
                        Math.floor(totalResults / 1000) * 1000
                      ).toLocaleString()}+`}
                </span>{' '}
                {totalResults === 109831
                  ? 'Total Statutes'
                  : 'Relevant Statutes'}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mr: 2.5,
                }}
              >
                <InputLabel
                  sx={{
                    marginRight: '10px',
                    fontSize: '1rem',
                    color: 'black',
                  }}
                  htmlFor="resultsCount"
                >
                  Show:
                </InputLabel>
                <FormControl
                  variant="outlined"
                  sx={{ width: 110, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                >
                  <Select
                    id="resultsCount"
                    value={resultsCount}
                    onChange={(e) => updateResultsCount(e.target.value)}
                    sx={{
                      fontSize: '0.875rem',
                      height: '36px',
                      // '& .MuiSelect-select': {
                      //   padding: '4px 0px 4px 4px',
                      //   display: 'flex',
                      //   alignItems: 'center',
                      // },
                    }}
                  >
                    <MenuItem value={10}>10 results</MenuItem>
                    <MenuItem value={20}>20 results</MenuItem>
                    <MenuItem value={50}>50 results</MenuItem>
                    <MenuItem value={100}>100 results</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <List
              sx={{
                p: 1,
                flexGrow: 1,
                overflowY: 'auto',
                '::-webkit-scrollbar': {
                  width: '5px',
                },
                '::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  marginTop: 3,
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#101936',
                  borderRadius: '6px',
                },
              }}
            >
              {results ? (
                results?.['search_results']?.map((statute) => (
                  <ListItem
                    sx={{
                      backgroundColor: 'white',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      transition: 'box-shadow 0.3s ease, transform 0.2s ease',
                      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                        transform: 'translateY(-2px)',
                      },
                      mt: 2,
                    }}
                    key={statute.Statute_Name}
                    onClick={() => handleStatuteNameClick(statute.Statute_Name)}
                  >
                    <Accordion
                      expanded={expandedResult === statute.Statute_Name}
                      onChange={() => handleResultToggle(statute.Statute_Name)}
                      sx={{
                        width: '100%',
                        border: 'none',
                        boxShadow: 'none',
                        p: 1,
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          borderRadius:
                            expandedResult === statute.Statute_Name
                              ? '4px 4px 0 0'
                              : '4px',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          color: '#00509e',
                        }}
                        expandIcon={<ExpandMore />}
                      >
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 'bold' }}
                          dangerouslySetInnerHTML={{
                            __html:
                              formatStatuteTitleHtml(statute.highlight) ||
                              formatStatuteTitle(statute.Statute_Name),
                          }}
                        />
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          borderRadius: '0 0 4px 4px',
                        }}
                      >
                        <List
                          sx={{
                            maxHeight: '50vh',
                            overflowY: 'auto',
                            paddingLeft: 2,
                            '::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '::-webkit-scrollbar-track': {
                              background: '#f1f1f1',
                            },
                            '::-webkit-scrollbar-thumb': {
                              background: '#101936',
                              borderRadius: '6px',
                            },
                          }}
                        >
                          {loading ? (
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 2,
                              }}
                            >
                              <CircularProgress size={18} />
                            </Box>
                          ) : sections?.[0]?.related_data.length === 0 ? (
                            <Typography
                              variant="body2"
                              sx={{
                                textAlign: 'center',
                                color: 'grey',
                                mt: 2,
                              }}
                            >
                              No sections available for this Statute.
                            </Typography>
                          ) : (
                            sections?.[0]?.related_data
                              ?.sort((a, b) => {
                                const sectionA = parseFloat(a.Section);
                                const sectionB = parseFloat(b.Section);
                                return sectionA - sectionB;
                              })
                              .map((result, index) => (
                                <React.Fragment key={result.id}>
                                  <Box
                                    onClick={(event) =>
                                      handleSubItemToggle(
                                        statute.Statute_Name,
                                        index,
                                        event
                                      )
                                    }
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      borderTop: '1px solid #e0e0e0',
                                      mr: 1,
                                    }}
                                  >
                                    <IconButton>
                                      {expandedSubItems[statute.Statute_Name]?.[
                                        index
                                      ] ? (
                                        <RemoveCircleOutline
                                          sx={{ color: '#1e90ff' }}
                                        />
                                      ) : (
                                        <AddCircleOutline
                                          sx={{ color: '#1e90ff' }}
                                        />
                                      )}
                                    </IconButton>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: 'bold',
                                        flex: 1,
                                      }}
                                    >
                                      {result.Section} -{' '}
                                      {formatStatuteTitle(result.Definition)}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <IconButton
                                        sx={{
                                          p: 0,
                                          color: '#d2042d',
                                          '&:hover': {
                                            backgroundColor: 'transparent',
                                          },
                                        }}
                                      >
                                        {bookmarkedStatuteIds.has(result.id) ? (
                                          <>
                                            <TurnedInIcon
                                              onClick={() =>
                                                handleUnBookmarkStatute(
                                                  result.id
                                                )
                                              }
                                              fontSize="small"
                                            />
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                ml: 0.5,
                                                color: '#00509e',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                  textDecoration: 'underline',
                                                },
                                              }}
                                              onClick={() =>
                                                handleUnBookmarkStatute(
                                                  result.id
                                                )
                                              }
                                            >
                                              Bookmarked
                                            </Typography>
                                          </>
                                        ) : (
                                          <>
                                            <TurnedInNotIcon
                                              sx={{
                                                '&:hover': {
                                                  backgroundColor:
                                                    'transparent',
                                                },
                                              }}
                                              onClick={() =>
                                                handleBookmarkClick(result)
                                              }
                                              fontSize="small"
                                            />
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                ml: 0.5,
                                                color: '#00509e',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                  textDecoration: 'underline',
                                                },
                                              }}
                                              onClick={() =>
                                                handleBookmarkClick(result)
                                              }
                                            >
                                              Bookmark
                                            </Typography>
                                          </>
                                        )}
                                      </IconButton>
                                    </Box>
                                  </Box>
                                  {expandedSubItems[statute.Statute_Name]?.[
                                    index
                                  ] && (
                                    <Box
                                      sx={{
                                        pl: 4,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{ p: 1 }}
                                        variant="body2"
                                        dangerouslySetInnerHTML={{
                                          __html: result.Statute_HTML,
                                        }}
                                      />
                                    </Box>
                                  )}
                                </React.Fragment>
                              ))
                          )}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </ListItem>
                ))
              ) : (
                <Typography>No results found.</Typography>
              )}
            </List>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Pagination
                count={Math.ceil(totalResults / resultsCount)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  mt: 1,
                  '& .MuiPaginationItem-root': {
                    '&:hover': {
                      color: '#fff',
                      backgroundColor: '#303f9f',
                    },
                    '&.Mui-selected': {
                      color: '#fff',
                      backgroundColor: '#213165',
                      '&:hover': {
                        backgroundColor: '#303f9f',
                      },
                    },
                  },
                }}
              />
              {snackbarMessage && (
                <Snackbar
                  open={!!snackbarMessage}
                  autoHideDuration={6000}
                  onClose={() => setSnackbarMessage(null)}
                >
                  <Alert
                    onClose={() => setSnackbarMessage(null)}
                    severity={snackbarMessage.type}
                    sx={{ width: '100%' }}
                  >
                    {snackbarMessage.text}
                  </Alert>
                </Snackbar>
              )}
            </Box>
          </>
        )}
      </Box>
      <Dialog
        open={openBookmarkModal}
        onClose={() => setOpenBookmarkModal(false)}
      >
        <DialogTitle sx={{ mb: 2 }}>Bookmark Statute</DialogTitle>
        <DialogContent sx={{ width: 400, overflow: 'visible' }}>
          <TextField
            sx={{ mt: 1 }}
            label="Add to existing group"
            select
            fullWidth
            value={selectedGroup}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === 'create-new') {
                setBookmarkGroupName('');
              }
              setSelectedGroup(selectedValue);
            }}
          >
            <MenuItem sx={{ color: '#303f9f' }} value="create-new">
              Create New Group
            </MenuItem>
            {bookmarkGroups.map((group) => (
              <MenuItem
                sx={{
                  maxWidth: '400px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                key={group._id}
                value={group._id}
              >
                {group.groupName}
              </MenuItem>
            ))}
          </TextField>

          {/* Conditionally render the input field if "Create New Group" is selected */}
          {selectedGroup === 'create-new' && (
            <TextField
              label="New Group Name"
              fullWidth
              value={bookmarkGroupName}
              onChange={(e) => setBookmarkGroupName(e.target.value)}
              sx={{ mt: 2 }}
            />
          )}

          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            multiline
            rows={3}
            value={statuteNote}
            onChange={(e) => setStatuteNote(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBookmarkModal(false)} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            onClick={
              selectedGroup === 'create-new'
                ? handleCreateBookmarkGroup
                : handleAddStatuteToGroup
            }
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StatuteSearch;
