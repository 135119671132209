import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  Button,
  Modal,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import LoadingModal from "./LoadingModal";

const functionAppUrl =
  "https://rg-law-gpt-be-auth2.azurewebsites.net/api/getUserBookmarkGroups";
const createGroupUrl =
  "https://rg-law-gpt-be-auth2.azurewebsites.net/api/createBookmarkGroup";
const deleteGroupUrl =
  "https://rg-law-gpt-be-auth2.azurewebsites.net/api/deleteBookmarkGroup";
const renameGroupUrl =
  "https://rg-law-gpt-be-auth2.azurewebsites.net/api/renameBookmarkGroup";

const BookmarkSidebar = ({ onGroupSelect }) => {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [editingGroup, setEditingGroup] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [groupNameInput, setGroupNameInput] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      try {
        const userId = userInfo._id;
        const response = await fetch(`${functionAppUrl}?userId=${userId}`);
        const data = await response.json();
        setGroups(data);

        if (data.length > 0) {
          const firstGroupId = data[0]._id;
          setSelectedGroup(firstGroupId);
          onGroupSelect(firstGroupId);
        }
      } catch (error) {
        console.error("Error fetching bookmark groups:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, [userInfo._id]);

  const handleGroupClick = (groupId) => {
    setSelectedGroup(groupId);
    onGroupSelect(groupId);
  };

  const confirmDeleteGroup = (groupId) => {
    setGroupToDelete(groupId);
    setConfirmationModalOpen(true);
  };

  const handleDeleteGroup = async () => {
    const groupId = groupToDelete;
    const groupName = groups.find((group) => group._id === groupId).groupName;

    if (groupName === "Bookmarks") return;

    try {
      const response = await fetch(deleteGroupUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id,
          groupId: groupId,
        }),
      });

      if (response.ok) {
        setGroups(groups.filter((group) => group._id !== groupId));
        if (selectedGroup === groupId) {
          setSelectedGroup(groups[0]?._id || null);
          onGroupSelect(groups[0]?._id || null);
        }
      } else {
        console.error("Failed to delete the group");
      }
    } catch (error) {
      console.error("Error deleting the group:", error);
    } finally {
      setConfirmationModalOpen(false);
    }
  };

  const handleRenameGroup = async (groupId) => {
    try {
      const response = await fetch(renameGroupUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id,
          groupId: groupId,
          newGroupName: newGroupName,
        }),
      });

      if (response.ok) {
        setGroups(
          groups.map((group) =>
            group._id === groupId
              ? { ...group, groupName: newGroupName }
              : group
          )
        );
        setEditingGroup(null);
      } else {
        console.error("Failed to rename the group");
      }
    } catch (error) {
      console.error("Error renaming the group:", error);
    }
  };

  const handleCreateGroup = async () => {
    if (!groupNameInput) return;

    try {
      const response = await fetch(createGroupUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userInfo._id,
          groupName: groupNameInput,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setGroups([
          ...groups,
          { _id: result.groupId, groupName: groupNameInput },
        ]);
        setModalOpen(false);
        setGroupNameInput("");
      } else {
        console.error("Failed to create the group:", result.message);
      }
    } catch (error) {
      console.error("Error creating the group:", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "278px",
        boxSizing: "border-box",
        flex: 1,
        bgcolor: "white",
        height: "calc(100vh - 50px)",
        overflowY: "auto",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#101936",
          borderRadius: "6px",
        },
      }}
    >
      <LoadingModal open={loading} />

      <Box sx={{ mr: 2 }}>
        <Button
          variant="outlined"
          fullWidth
          startIcon={<AddIcon />}
          onClick={() => setModalOpen(true)}
          sx={{
            color: "#303f9f",
            margin: "10px 0",
            textTransform: "none",
          }}
        >
          Create Group
        </Button>
        <Typography sx={{ mt: 2 }} variant="h6">
          Bookmark Groups
        </Typography>

        <List>
          {groups.map((group) => (
            <ListItem
              key={group._id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor:
                  selectedGroup === group._id ? "#303f9f" : "white",
                color: selectedGroup === group._id ? "white" : "black",
                "&:hover": {
                  backgroundColor:
                    selectedGroup === group._id ? "#303f9f" : "lightgrey",
                },
                borderRadius: 1,
                mt: 1,
              }}
            >
              {editingGroup === group._id ? (
                <TextField
                  value={newGroupName}
                  onChange={(e) => setNewGroupName(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") handleRenameGroup(group._id);
                  }}
                  size="small"
                  sx={{
                    backgroundColor: "white",
                    borderRadius: 1,
                    mr: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                />
              ) : (
                <Tooltip title={group.groupName} arrow>
                  <ListItemText
                    primary={
                      group.groupName.length > 20
                        ? `${group.groupName.slice(0, 22)}...`
                        : group.groupName
                    }
                    onClick={() => handleGroupClick(group._id)}
                    sx={{
                      cursor: "pointer",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  />
                </Tooltip>
              )}
              {editingGroup === group._id ? (
                <IconButton
                  edge="end"
                  aria-label="confirm"
                  onClick={() => handleRenameGroup(group._id)}
                  sx={{
                    color: selectedGroup === group._id ? "white" : "black",
                  }}
                >
                  <CheckIcon />
                </IconButton>
              ) : (
                group.groupName !== "All Bookmarks" && (
                  <>
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={() => {
                        setEditingGroup(group._id);
                        setNewGroupName(group.groupName);
                      }}
                      sx={{
                        color: selectedGroup === group._id ? "white" : "black",
                        mr: 1,
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => confirmDeleteGroup(group._id)}
                      sx={{
                        color:
                          selectedGroup === group._id ||
                          group.groupName === "Bookmarks"
                            ? "white"
                            : selectedGroup === group._id
                            ? "white"
                            : "black",
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                )
              )}
            </ListItem>
          ))}
        </List>
      </Box>

      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "white",
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6">Create New Group</Typography>
          <TextField
            label="Group Name"
            value={groupNameInput}
            onChange={(e) => setGroupNameInput(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            onClick={handleCreateGroup}
            sx={{ mt: 2, width: "100%" }}
          >
            Create
          </Button>
        </Box>
      </Modal>

      <Modal
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "white",
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Confirm Deletion
          </Typography>
          <Typography variant="body1" gutterBottom>
            Are you sure you want to delete this group?
          </Typography>
          <Box sx={{ display: "flex", mt: 2, justifyContent: "flex-end" }}>
            <Button
              sx={{ bgcolor: "#303f9f" }}
              variant="contained"
              onClick={handleDeleteGroup}
            >
              Yes
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="error"
              onClick={() => setConfirmationModalOpen(false)}
            >
              No
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default BookmarkSidebar;
