import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { userLoginReducer, userSignUpReducer } from './reducers/userReducers';
import {
  chatHistoryReducer,
  chatTitlesReducer,
  checkServerReducer,
  deleteChatReducer,
  editTitleReducer,
  newChatReducer,
} from './reducers/chatReducers';
import {
  deletePetitionReducer,
  editPetitionTitleReducer,
  newPetitionReducer,
  petitionHistoryReducer,
  petitionTitlesReducer,
} from './reducers/petitionReducers';
import {
  caseDetailReducer,
  caseDetailsFromCosmosReducer,
  caseFiltersReducer,
  searchFacetsReducer,
  searchFilterPaginationReducer,
  searchFilterValuesReducer,
  searchResultsCountReducer,
  searchResultsReducer,
  searchSelectedFiltersReducer,
} from './reducers/searchReducers';
import statuteSearchReducer, {
  statutePaginationReducer,
  statuteSearchFilterReducer,
  statuteSectionsReducer,
} from './reducers/statuteSearchReducer';
import { statuteTitleReducer } from './reducers/statuteSearchReducer';
import {
  applicationTitlesReducer,
  deleteApplicationReducer,
} from './reducers/applicationReducers';

const reducer = {
  userLogin: userLoginReducer,
  userSignup: userSignUpReducer,
  chatTitles: chatTitlesReducer,
  petitionTitles: petitionTitlesReducer,
  newChat: newChatReducer,
  newPetition: newPetitionReducer,
  chatHistory: chatHistoryReducer,
  petitionHistory: petitionHistoryReducer,
  editChat: editTitleReducer,
  editPetitionTitle: editPetitionTitleReducer,
  deleteChat: deleteChatReducer,
  deletePetition: deletePetitionReducer,
  serverStatus: checkServerReducer,
  searchResultsCount: searchResultsCountReducer,
  searchResults: searchResultsReducer,
  searchFacets: searchFacetsReducer,
  searchFilters: searchFilterValuesReducer,
  searchFiltersPagination: searchFilterPaginationReducer,
  selectedFilters: searchSelectedFiltersReducer,
  caseDetail: caseDetailReducer,
  caseDetailsFromCosmos: caseDetailsFromCosmosReducer,
  caseFilters: caseFiltersReducer,
  statutePagination: statutePaginationReducer,
  statuteSearch: statuteSearchReducer,
  isStatuteFilterApplied: statuteSearchFilterReducer,
  statuteSections: statuteSectionsReducer,
  statuteTitle: statuteTitleReducer,
  applicationTitles: applicationTitlesReducer,
  deleteApplication: deleteApplicationReducer,
};
const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null;

const preloadedState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const store = configureStore({
  reducer,
  middleware,
  preloadedState,
});

export default store;
