import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Radio,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Tooltip,
} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import GavelIcon from "@mui/icons-material/Gavel";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import SearchIcon from "@mui/icons-material/Search";
import { blue, green, red, orange, grey, yellow } from "@mui/material/colors";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import HTMLDocx from "html-docx-js/dist/html-docx";
import { saveAs } from "file-saver";
import { DescriptionOutlined } from "@mui/icons-material";
import { color, Stack } from "@mui/system";
import { Print } from "@mui/icons-material";
import AbcTwoToneIcon from "@mui/icons-material/AbcTwoTone";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const staticData = [
  { chat_title: "Document 1", name: "Summarization", date: "2023-08-18" },
  { chat_title: "Document 2", name: "Citation", date: "2023-08-17" },
  { chat_title: "Document 3", name: "Validation", date: "2023-08-16" },
];

const DocumentAnalysis = ({ selectedAnalysisTab }) => {
  const [textInput, setTextInput] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [analysisResults, setAnalysisResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    setFile(uploadedFile);
    setTextInput("");
  };

  const handleTextInputChange = (e) => {
    const text = e.target.value;
    setTextInput(text);
    if (text) {
      setFile(null); // Clear file input when text is entered
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleAnalyze = (selectedOption) => {
    if (!file && !textInput) {
      alert("Please upload a file or enter text!");
      return;
    }

    const results = [];
    switch (selectedOption) {
      case "summarize":
        results.push({
          title: "Summary",
          content: "This is a summary of the document.",
        });
        break;
      case "similarLaws":
        results.push({
          title: "Similar Laws",
          content: "These are the similar laws.",
        });
        break;
      case "validate":
        results.push({
          title: "Validation",
          content: "The document has been validated.",
        });
        break;
      case "extractReferences":
        results.push({
          title: "Extract References",
          content: "List of extracted references.",
        });
        break;
      case "extractArguments":
        results.push({
          title: "Extracted Arguments",
          content: "Key arguments from the document.",
        });
        break;
      case "enhanceArguments":
        results.push({
          title: "Enhanced Arguments",
          content: "Enhanced arguments for the document.",
        });
        break;
      case "counterArguments":
        results.push({
          title: "Counter Arguments",
          content: "Possible counterarguments.",
        });
        break;
      case "improveText":
        results.push({
          title: "Improve Text",
          content: "Improved text.",
        });
        break;
      default:
        alert("Invalid selection.");
    }

    setAnalysisResults(results);
  };

  const renderOutlinedButtonWithIcon = (name, label, Icon, color) => (
    <Button
      variant="outlined"
      onClick={() => handleAnalyze(name)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        borderRadius: "12px",
        width: "100%",
        mt: 1,
        height: 90,
        padding: "12px",
        // boxShadow: "0 8px 24px rgba(0, 0, 0, 0.2)",
        backdropFilter: "blur(15px)",
        textTransform: "none",
        minWidth: "150px",
        maxWidth: "320px",
        // width: "100%",
        "@media (max-width: 600px)": {
          minWidth: "120px",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Icon sx={{ mr: 1, color: color }} />
        <Typography>{label}</Typography>
      </Box>
    </Button>
  );
  const marginRightStyleImproveText = { marginRight: 10 };
  const marginRightSimilarLaws = { marginRight: 10 };

  const downloadDocx = () => {
    if (!file) {
      alert("No file uploaded!");
      return;
    }

    if (analysisResults.length === 0) {
      alert("No content to download!");
      return;
    }

    let docContent = "<html><body>";
    analysisResults.forEach((result) => {
      docContent += `<h3>${result.title}</h3>`;
      docContent += `<p>${result.content}</p>`;
    });
    docContent += "</body></html>";

    const docxBlob = HTMLDocx.asBlob(docContent);

    const originalFileName = file.name;
    const baseFileName =
      originalFileName.substring(0, originalFileName.lastIndexOf(".")) ||
      originalFileName;

    const downloadFileName = `${baseFileName}_Analyzed_Document.docx`;

    saveAs(docxBlob, downloadFileName);
  };

  const handlePrint = () => {
    if (analysisResults.length === 0) {
      alert("No content to print!");
      return;
    }

    const printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write("<html><head><title>Print</title>");
    printWindow.document.write("</head><body >");
    printWindow.document.write(
      analysisResults
        .map((result) => `<h3>${result.title}</h3><p>${result.content}</p>`)
        .join("")
    );
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  if (selectedAnalysisTab === 1) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          height: "calc(100vh - 50px)",
          flex: 5,
          bgcolor: "#f5f5f5",
          p: 2,
          overflow: "auto",
        }}
      >
        <Typography variant="h5" sx={{ color: "#303f9f", mt: 2, mb: 2 }}>
          Analyzed Documents
        </Typography>
        <Box sx={{ mb: 2 }}>
          <TextField
            variant="outlined"
            size="small"
            sx={{ width: "40%" }}
            placeholder="Search Document by Title"
            InputProps={{
              startAdornment: <SearchIcon sx={{ color: "grey", mr: 1 }} />,
              sx: {
                borderRadius: "30px",
                fontSize: "14px",
                backgroundColor: "white",
              },
            }}
          />
        </Box>
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#303f9f",
                    color: "white",
                  }}
                >
                  Document Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#303f9f",
                    color: "white",
                  }}
                >
                  Features Analyzed
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#303f9f",
                    color: "white",
                  }}
                >
                  Date Saved
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "#303f9f",
                    color: "white",
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staticData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" } }}
                >
                  <TableCell sx={{ padding: "8px 16px", fontSize: "14px" }}>
                    {row.chat_title}
                  </TableCell>
                  <TableCell sx={{ padding: "8px 16px", fontSize: "14px" }}>
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ padding: "8px 16px", fontSize: "14px" }}>
                    {row.date}
                  </TableCell>
                  <TableCell sx={{ padding: "8px 16px", fontSize: "14px" }}>
                    <Tooltip title="Preview">
                      <IconButton sx={{ color: [blue[500]] }}>
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <IconButton sx={{ color: [green[500]] }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download">
                      <IconButton sx={{ color: [orange[500]] }}>
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton sx={{ color: [red[500]] }}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        height: "calc(100vh - 50px)",
        flex: 5,
        bgcolor: "#f5f5f5",
        p: 2,
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          flexDirection: "column",
        }}
      >
        <DescriptionOutlined
          fontSize="large"
          sx={{ mr: 1, color: "#303f9f" }}
        />
        <Typography variant="h5" sx={{ color: "#303f9f" }}>
          Legal Document Analysis
        </Typography>
      </Box>
      <Typography sx={{ color: "#303f9f", mb: 2 }}>
        Please upload a file or input text.
      </Typography>
      <Box>
        <TextField
          type="file"
          onChange={handleFileChange}
          inputProps={{
            accept: "file/*",
            style: { display: "none" },
          }}
          id="custom-file-input"
        />

        <label htmlFor="custom-file-input">
          <Button
            variant="contained"
            component="span"
            sx={{
              backgroundColor: "#00509e",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#003366",
              },
              mb: 2,
            }}
          >
            Choose a file
          </Button>
        </label>

        {file && (
          <Typography variant="body2" sx={{ mt: 1, fontWeight: "bold" }}>
            {file.name}
          </Typography>
        )}
      </Box>

      <TextField
        label="Enter or paste text."
        multiline
        rows={4}
        value={textInput}
        onChange={handleTextInputChange}
        fullWidth
        sx={{ mb: 2, bgcolor: "white", mt: 2 }}
      />
      <Grid
        container
        sx={{
          bgcolor: "white",
          p: 1,
        }}
      >
        <Grid item xs={3}>
          {renderOutlinedButtonWithIcon(
            "summarize",
            "Summarize Text",
            DehazeIcon,
            blue[500]
          )}
          {renderOutlinedButtonWithIcon(
            "improveText",
            "Improve Text",
            AbcTwoToneIcon,
            green[900]
          )}
        </Grid>
        <Grid item xs={3}>
          {renderOutlinedButtonWithIcon(
            "similarLaws",
            "Find Similar Laws",
            GavelIcon,
            orange[500]
          )}
          {renderOutlinedButtonWithIcon(
            "validate",
            "Validate References",
            TaskAltIcon,
            green[500]
          )}
        </Grid>
        <Grid item xs={3}>
          {renderOutlinedButtonWithIcon(
            "extractReferences",
            "Extract References",
            CompareArrowsIcon,
            grey[500]
          )}
          {renderOutlinedButtonWithIcon(
            "extractArguments",
            "Extract Arguments",
            CallSplitIcon,
            blue[900]
          )}
        </Grid>
        <Grid item xs={3}>
          {renderOutlinedButtonWithIcon(
            "enhanceArguments",
            "Enhance Arguments",
            LightbulbIcon,
            yellow[700]
          )}
          {renderOutlinedButtonWithIcon(
            "counterArguments",
            "Generate Counter Arguments",
            AutorenewIcon,
            red[500]
          )}
        </Grid>
      </Grid>

      {/* <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          sx={{ bgcolor: "#303f9f", textTransform: "none" }}
          onClick={handleAnalyze}
          size="large"
        >
          Analyze
        </Button>
      </Box> */}
      {analysisResults.map((result, index) => (
        <Box>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: "#303f9f" }}
          >
            Analyzed Documents
          </Typography>
          <Box
            key={index}
            sx={{
              mt: 2,
              mb: 2,
              border: "1px solid grey",
              p: 2,
              borderRadius: 2,
              bgcolor: "white",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#303f9f" }}
            >
              {result.title}
            </Typography>
            <Typography variant="body1">{result.content}</Typography>
          </Box>
        </Box>
      ))}
      {analysisResults.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
          <Button
            variant="contained"
            size="small"
            sx={{ bgcolor: "teal", mr: 1 }}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ bgcolor: "green", mr: 1 }}
            startIcon={<DownloadIcon />}
            onClick={downloadDocx}
          >
            Download
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ bgcolor: "#303f9f" }}
            startIcon={<Print />}
            onClick={handlePrint}
          >
            Print
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DocumentAnalysis;
