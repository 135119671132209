const config = {
  backendUrl: 'https://rg-law-gpt-be-auth2.azurewebsites.net/api',
  apiUrl: 'https://rg-law-gpt-openai-functions.azurewebsites.net/api',
  // petitionUrl: 'https://rg-law-gpt-ml-petition-functions.azurewebsites.net/api',
  petitionUrl:
    'https://rg-lawgpt-legal-document-generation.azurewebsites.net/api',
  petitionForm:
    'https://rg-lawgpt-legal-document-generation.azurewebsites.net/api',
  searchUrl: 'https://search-engine-lawgpt.azurewebsites.net/api/search',
};

export default config;
