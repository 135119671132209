import config from '../config/api';

export const STATUTE_SEARCH_REQUEST = 'STATUTE_SEARCH_REQUEST';
export const STATUTE_SEARCH_SUCCESS = 'STATUTE_SEARCH_SUCCESS';
export const STATUTE_SEARCH_FAILURE = 'STATUTE_SEARCH_FAILURE';
export const STATUTE_SECTIONS_REQUEST = 'STATUTE_SECTIONS_REQUEST';
export const STATUTE_SECTIONS_SUCCESS = 'STATUTE_SECTIONS_SUCCESS';
export const STATUTE_SECTIONS_FAILURE = 'STATUTE_SECTIONS_FAILURE';

export const statuteSearchResults =
  (userQuery, searchMode, top, skip) => async (dispatch) => {
    try {
      dispatch({
        type: STATUTE_SEARCH_REQUEST,
      });
      const endpoint = `${config.searchUrl}/statutes`;

      const requestBody = {
        facets: [
          // 'Statute_Name,count:0,sort:value',
          // 'Year,count:50,sort:-value',
          // 'Section,count:50,sort:value',
          // 'Act_Ordinance,count:50,sort:value',
        ],
        query: userQuery,
        filter: '',
        mode: searchMode,
        sort_by: '',
        top: top,
        skip: skip,
        count: true,
      };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Filter out results with the specific text
        // const filteredResults = responseData.value.filter(
        //   (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
        // );
        dispatch({
          type: STATUTE_SEARCH_SUCCESS,
          payload: responseData,
        });
      } else {
        throw new Error('Failed to fetch statute search results');
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SEARCH_FAILURE,
        payload: error.message,
      });
    }
  };

export const searchStatutesByYear = (userQuery, year) => async (dispatch) => {
  try {
    const endpoint = `${config.searchUrl}/statutes`;

    const requestBody = {
      facets: [
        // 'Statute_Name,count:0,sort:value',
        // 'Year,count:50,sort:-value',
        // 'Section,count:50,sort:value',
        // 'Act_Ordinance,count:50,sort:value',
      ],
      query: userQuery,
      filter: `Year eq '${year}'`,
      mode: 'keyword',
      sort_by: '',
      top: 10,
      skip: 0,
      count: true,
    };
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const responseData = await response.json();

      // Filter out results with the specific text
      // const filteredResults = responseData.value.filter(
      //   (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
      // );
      dispatch({
        type: STATUTE_SEARCH_SUCCESS,
        payload: responseData,
      });
    } else {
      throw new Error('Failed to fetch statute search results');
    }
  } catch (error) {
    dispatch({
      type: STATUTE_SEARCH_FAILURE,
      payload: error.message,
    });
  }
};

export const searchStatutesByLetter = (letter) => async (dispatch) => {
  try {
    const nextLetter = String.fromCharCode(letter.charCodeAt(0) + 1);
    const endpoint = `${config.searchUrl}/statutes`;

    const requestBody = {
      facets: [
        // 'Statute_Name,count:0,sort:value',
        // 'Year,count:50,sort:-value',
        // 'Section,count:50,sort:value',
        // 'Act_Ordinance,count:50,sort:value',
      ],
      query: '',
      filter: `Statute_Name ge '${letter}' and Statute_Name lt '${nextLetter}'`,
      mode: 'keyword',
      sort_by: '',
      top: 10,
      skip: 0,
      count: true,
    };
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const responseData = await response.json();

      // Filter out results with the specific text
      // const filteredResults = responseData.value.filter(
      //   (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
      // );
      dispatch({
        type: STATUTE_SEARCH_SUCCESS,
        payload: responseData,
      });
    } else {
      throw new Error('Failed to fetch statute search results');
    }
  } catch (error) {
    dispatch({
      type: STATUTE_SEARCH_FAILURE,
      payload: error.message,
    });
  }
};

export const applyStatuteFilters =
  (userQuery, filters, top = 10, skip = 0) =>
  async (dispatch) => {
    try {
      dispatch({
        type: STATUTE_SEARCH_REQUEST,
      });
      const { year, letter } = filters;
      const endpoint = `${config.searchUrl}/statutes`;

      let filterString = '';

      if (year) {
        filterString += `Year eq '${year}'`;
      }

      if (letter) {
        const nextLetter = String.fromCharCode(letter.charCodeAt(0) + 1);
        // If there's already a year filter, concatenate with `and`
        if (filterString) {
          filterString += ` and Statute_Name ge '${letter}' and Statute_Name lt '${nextLetter}'`;
        } else {
          filterString = `Statute_Name ge '${letter}' and Statute_Name lt '${nextLetter}'`;
        }
      }

      const requestBody = {
        query: userQuery || '',
        filter: filterString || '',
        mode: 'keyword',
        sort_by: '',
        top: top,
        skip: skip || 0,
        count: true,
      };

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Dispatch the success action with the data
        dispatch({
          type: STATUTE_SEARCH_SUCCESS,
          payload: responseData,
        });
      } else {
        throw new Error('Failed to fetch statute search results');
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SEARCH_FAILURE,
        payload: error.message,
      });
    }
  };

export const searchStatutesBySection =
  (userQuery, section) => async (dispatch) => {
    try {
      const endpoint = `${config.searchUrl}/indexes/statutes-index-v5/docs/search?api-version=2024-05-01-preview`;
      const apiKey = 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv';

      const requestBody = {
        facets: [
          'Statute_Name,count:0,sort:value',
          'Year,count:50,sort:-value',
          'Section',
          'Act_Ordinance,sort:count',
        ],
        top: 0,
        searchMode: 'any',
        filter: `Section eq '${section}'`,
        queryType: 'full',
        search: userQuery,
      };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Filter out results with the specific text
        // const filteredResults = responseData.value.filter(
        //   (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
        // );
        dispatch({
          type: STATUTE_SEARCH_SUCCESS,
          payload: responseData,
        });
      } else {
        throw new Error('Failed to fetch statute search results');
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SEARCH_FAILURE,
        payload: error.message,
      });
    }
  };

export const searchSections =
  (userQuery, statuteName, searchMode) => async (dispatch) => {
    try {
      dispatch({
        type: STATUTE_SECTIONS_REQUEST,
      });

      const endpoint = `${config.searchUrl}/statutes`;

      const requestBody = {
        facets: [],
        query: userQuery,
        filter: `(Statute_Name eq '${statuteName}')`,
        mode: searchMode,
        sort_by: '',
        top: 50,
        skip: 0,
      };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Filter out results with the specific text
        const filteredResults = responseData['search_results'].filter(
          (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
        );
        dispatch({
          type: STATUTE_SECTIONS_SUCCESS,
          payload: filteredResults,
        });
      } else {
        throw new Error('Failed to fetch statute search results');
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SECTIONS_FAILURE,
        payload: error.message,
      });
    }
  };

export const searchStatutesByActOrdinance =
  (userQuery, actOrdinance) => async (dispatch) => {
    try {
      const endpoint = `${config.searchUrl}/indexes/statutes-index-v5/docs/search?api-version=2024-05-01-preview`;
      const apiKey = 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv';

      const requestBody = {
        facets: [
          'Statute_Name,count:0,sort:value',
          'Year,count:50,sort:-value',
          'Section',
          'Act_Ordinance',
        ],
        top: 0,
        searchMode: 'any',
        filter: `Act_Ordinance eq '${actOrdinance}'`,
        queryType: 'full',
        search: userQuery,
      };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Filter out results with the specific text
        // const filteredResults = responseData.value.filter(
        //   (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
        // );
        dispatch({
          type: STATUTE_SEARCH_SUCCESS,
          payload: responseData,
        });
      } else {
        throw new Error('Failed to fetch statute search results');
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SEARCH_FAILURE,
        payload: error.message,
      });
    }
  };

export const searchActOrdinance =
  (userQuery, statuteName) => async (dispatch) => {
    try {
      dispatch({
        type: STATUTE_SECTIONS_REQUEST,
      });

      const endpoint = `${config.searchUrl}/indexes/statutes-index-v5/docs/search?api-version=2024-05-01-preview`;
      const apiKey = 'unsTowEg7mg8dXgT5RZj01BtxYR49IJwJcUbxx2duzAzSeBKrwIv';

      const requestBody = {
        facets: [
          'Statute_Name,sort:count',
          'Year,sort:count',
          'Section,sort:count',
          'Act_Ordinance',
        ],
        filter: `(Act_Ordinance eq '${statuteName}')`,
        searchMode: 'any',
        queryType: 'full',
        search: userQuery,
      };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': apiKey,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const responseData = await response.json();

        // Filter out results with the specific text
        const filteredResults = responseData.value.filter(
          (item) => item.Statute !== 'Error Code:700 Sorry Something went wrong'
        );
        dispatch({
          type: STATUTE_SECTIONS_SUCCESS,
          payload: filteredResults,
        });
      } else {
        throw new Error('Failed to fetch statute search results');
      }
    } catch (error) {
      dispatch({
        type: STATUTE_SECTIONS_FAILURE,
        payload: error.message,
      });
    }
  };
