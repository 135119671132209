import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Avatar,
  TextField,
  IconButton,
  Button,
  Tooltip,
  Typography,
  CircularProgress,
  Skeleton,
  Fab,
  Dialog,
  DialogContent,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Modal,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import Zoom from '@mui/material/Zoom';
import MenuIcon from '@mui/icons-material/Menu';
import IosShareIcon from '@mui/icons-material/IosShare';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SendIcon from '@mui/icons-material/Send';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { v4 as uuidv4 } from 'uuid';
import { saveAs } from 'file-saver';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config/api';
import { getChatTitles } from '../actions/chatActions';
import BookListingDialog from './BookListingDialog';
import AlertDialog from './AlertDialog';
import Feedback from './Feedback';
import { trackEvent, trackEventWithResponseTime } from '../analytics';
import { formatRefLabel, wait } from '../utils/textFormatting';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CaseLawPreviewModal from './UI/CaseLawPreviewModal';
import CaseLawsSidebar from './UI/CaseLawsSidebar';
import ChatModeBox from './UI/ChatModeBox';
import { findCaseFromAzureAiSearch } from '../actions/searchActions';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import { KeyboardVoice } from '@mui/icons-material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CancelIcon from '@mui/icons-material/Cancel';
import Markdown from 'react-markdown';
import CloseIcon from '@mui/icons-material/Close';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import TurnedInIcon from '@mui/icons-material/TurnedIn';

const deleteCaseUrl =
  'https://rg-law-gpt-be-auth2.azurewebsites.net/api/deleteCaseOrStatuteFromGroup';

const tooltips = [
  'Legal answers from both statutes and case laws',
  'Statute-specific legal analysis',
  'Case-specific legal analysis',
];

const markdownStyles = {
  ul: {
    margin: '0px',
    paddingLeft: '20px',
    fontSize: '14px',
  },
  ol: {
    margin: '0px',
    paddingLeft: '20px',
    marginTop: '2px',
    fontSize: '14px',
  },
  li: {
    margin: '0px',
    padding: '0px',
    marginTop: '2px',
    fontSize: '14px',
  },
  p: {
    margin: '0px',
    padding: '0px',
    fontSize: '14px',
  },
};

// Default Function Component
const ChatInterface = ({ toggleSidebar }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statuteRelevantCasesLoading, setStatuteRelevantCasesLoading] =
    useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [chatTitle, setChatTitle] = useState('');
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [finalChatId, setFinalChatId] = useState('');
  const [openListingDialog, setOpenListingDialog] = useState(false);
  const [alignment, setAlignment] = useState('balanced');
  const [selectedBook, setSelectedBook] = useState(null);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [response, setResponse] = useState('');
  const [feedbackQuery, setFeebackQuery] = useState('');
  const [feedbackRefs, setFeedbackRefs] = useState([]);
  const [feedbackGiven, setFeedbackGiven] = useState({});
  const [messageIndex, setMessageIndex] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isCaseLawClicked, setIsCaseLawClicked] = useState(false);
  const [statuteRelevantCases, setStatuteRelevantCases] = useState(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [fullscreen, setFullscreen] = React.useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [placeholder, setPlaceholder] = useState('Ask any legal question...');
  const silenceTimeoutRef = useRef(null);
  const finalTranscriptRef = useRef('');
  const [isVoiceInput, setIsVoiceInput] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);
  const [bookmarkGroupName, setBookmarkGroupName] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [bookmarkGroups, setBookmarkGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [caseNote, setCaseNote] = useState('');
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const [bookmarkedCaseIds, setBookmarkedCaseIds] = useState(new Set());
  const [selectedStatute, setSelectedStatute] = useState(null);
  const [statuteNote, setStatuteNote] = useState('');
  const [bookmarkedStatuteIds, setBookmarkedStatuteIds] = useState(new Set());
  const [openStatuteModal, setOpenStatuteModal] = useState(false);

  const chatContainerStartRef = useRef(null);
  const chatContainerEndRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const chatHistory = useSelector((state) => state.chatHistory);
  const { loading: historyLoading, history } = chatHistory;

  const newChat = useSelector((state) => state.newChat);
  const { isClicked } = newChat;

  const editChat = useSelector((state) => state.editChat);
  const { success: successEdit } = editChat;

  const deleteChat = useSelector((state) => state.deleteChat);
  const { success: successDelete } = deleteChat;

  const statuteContentWithStyles = `
  <style>
    .Section1 * {
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
    }
     .Section1 b {
      color: #2980b9;
    }
  </style>
  ${modalContent}
`;

  const handlePreviewClick = (caseItem) => {
    dispatch(findCaseFromAzureAiSearch(caseItem.label, caseItem.court));
    setOpenPreview(true);
  };

  const handleFullscreenToggle = () => {
    setFullscreen(!fullscreen);
  };

  const handleCloseCaseLawsSidebar = () => {
    setIsCaseLawClicked(false);
  };

  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };

  const handleCloseListingDialog = () => {
    setOpenListingDialog(false);
  };

  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };

  const handleFeedbackGiven = (messageIndex) => {
    setFeedbackGiven({ ...feedbackGiven, [messageIndex]: true });
  };

  const handleButtonClick = (value) => {
    setAlignment(value);
  };

  const formatCaseTitle = (title) => {
    if (!title) return;
    const splitTitle = title.split('Honorable')[0];
    const titleCaseTitle = splitTitle
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
    return titleCaseTitle;
  };

  const formatCourtName = (courtName) => {
    if (!courtName) return '';

    return courtName
      .toLowerCase()
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const fetchStatuteRelevantCases = async (citationNames) => {
    setStatuteRelevantCasesLoading(true);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const requestBody = JSON.stringify({
      statute: {
        Citations: citationNames,
      },
    });
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: requestBody,
      redirect: 'follow',
    };
    fetch(`${config.apiUrl}/find_relevant_cases_to_statute`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result && Array.isArray(result.relevant_cases)) {
          setStatuteRelevantCases(result?.relevant_cases);
        } else {
          setStatuteRelevantCases([]);
        }
        setStatuteRelevantCasesLoading(false);
      })
      .catch((error) => {
        setStatuteRelevantCasesLoading(false);
        console.log(error);
      });
  };

  const handleCaseLawsClick = (citationNames) => {
    console.log(citationNames);
    const formattedArray = citationNames.map((name) => ({
      Citation_Name: name,
    }));
    setIsCaseLawClicked(true);
    fetchStatuteRelevantCases(formattedArray);
  };

  const updateChatTitle = useCallback(async () => {
    const endpoint = `${config.apiUrl}/get_chat_title`;
    const response = await fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({ user_id: userInfo._id, chat_id: finalChatId }),
    });
    if (response.ok) {
      let title = await response.json();
      setChatTitle(title);
    }
  }, [userInfo, finalChatId]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const generateChatbotResponse = useCallback(
    async (userMessage) => {
      let keyword;
      let eventAction = '';

      if (alignment === 'balanced') {
        keyword = 'hybrid_chat';
        eventAction = 'Free Form Query';
      }
      if (alignment === 'precise') {
        keyword = 'statute_chat';
        eventAction = 'Structured Query';
      }
      if (alignment === 'case law') {
        keyword = 'case_chat';
        eventAction = 'Case Law Query';
      }
      const balancedAzureFn = `${config.apiUrl}/${keyword}`;
      const data = {
        user_id: userInfo._id,
        chat_id: finalChatId,
        temperature: 'precise',
        answer_length: 'high',
        query: userMessage,
      };

      const startTime = new Date();

      trackEvent('Chat', 'UserQuery', `User: ${userInfo.name}`);

      try {
        setIsLoading(true);
        const response = await fetch(balancedAzureFn, {
          method: 'POST',
          body: JSON.stringify(data),
        });

        const endTime = new Date();
        const responseTimeMilliseconds = endTime - startTime;

        if (!response.ok) {
          throw new Error('Failed to response');
        }

        trackEvent('Chat', eventAction, `User: ${userInfo.name}`);
        trackEventWithResponseTime(
          'Chat',
          'Response Time',
          `User Message: ${userMessage}`,
          responseTimeMilliseconds
        );

        return await response.json();
      } catch (error) {
        toast.error('Something went wrong. Please try again later.', {
          position: 'top-right',
        });
      } finally {
        setIsLoading(false);
      }
    },
    [alignment, finalChatId, userInfo]
  );

  const handleSendMessage = useCallback(async () => {
    // Ignore empty messages
    if (inputMessage.trim() === '') return;
    if (location.pathname === '/home') {
      return navigate(`/app/legal-chat?query=${inputMessage}`);
    }
    trackEvent('Chat', 'Chat Initiated', `User: ${userInfo.name}`);
    const newMessages = [
      ...(messages || []),
      { text: { response: inputMessage }, isUser: true },
    ];
    setMessages(newMessages);
    setInputMessage('');

    // Simulate a response from the chatbot
    const chatbotResponse = await generateChatbotResponse(inputMessage);
    if (chatbotResponse) {
      const updatedMessages = [
        ...newMessages,
        {
          text: chatbotResponse,
          isUser: false,
        },
      ];
      setMessages(updatedMessages);
    }
    if (messages.length <= 1) {
      dispatch(getChatTitles(userInfo?._id));
      updateChatTitle();
    }
    // Clear the input field
    //setInputMessage('');
  }, [
    dispatch,
    location,
    navigate,
    inputMessage,
    generateChatbotResponse,
    userInfo,
    messages,
    updateChatTitle,
  ]);

  const handleCopyClick = async (responseText) => {
    setIsCopied(true);
    try {
      await navigator.clipboard.writeText(responseText);
      await wait();
      setIsCopied(false);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleExportChat = () => {
    const chatText = messages
      .map((message) => {
        return message.isUser
          ? `User: ${message.text.response}`
          : `Assistant: ${message.text.response}\nReferences:\n${
              message.text.references
                ? message.text.references
                    ?.map((ref) => {
                      return `Label: ${ref.label}\nContent: ${ref.content}\n URL: ${ref.url}`;
                    })
                    .join('\n\n')
                : 'Not Applicable'
            }`;
      })
      .join('\n');

    const blob = new Blob([chatText], { type: 'text/plain' });

    saveAs(blob, `${chatTitle}.txt`);
  };

  const handleStatutePreview = (content) => {
    setModalContent(content);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateSelectedBook = (book) => {
    setSelectedBook(book);
  };

  const handleChangeChat = (value) => {
    if (location.pathname === '/home') {
      navigate('/app/legal-chat');
    }
    if (value !== null) {
      setAlignment(value);
    }
  };

  const handleCloseFeedbackDialog = () => {
    setOpenFeedbackDialog(false);
  };

  const handleFeedbackClick = (response, references, index) => {
    const userQuery = messages[index - 1]?.text.response;
    setFeebackQuery(userQuery);
    setFeedbackRefs(references);
    setResponse(response);
    setOpenFeedbackDialog(true);
    setMessageIndex(index);
  };

  const handleScroll = () => {
    const container = chatContainerStartRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      const threshold = 100;
      const shouldShow = scrollTop + clientHeight < scrollHeight - threshold;
      setShowScrollButton(shouldShow);
    }
  };

  const scrollToBottom = () => {
    chatContainerEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const recognition = new window.webkitSpeechRecognition();
  recognition.continuous = true;
  recognition.interimResults = true;
  recognition.lang = 'en-US';

  recognition.onresult = (event) => {
    const transcript = Array.from(event.results)
      .map((result) => result[0].transcript)
      .join('');
    setInputMessage(transcript);
    finalTranscriptRef.current = transcript;
    setIsVoiceInput(true);
    resetSilenceTimer();
  };

  recognition.onerror = (event) => {
    console.error(event.error);
    stopListening();
  };

  const startListening = () => {
    setIsListening(true);
    setPlaceholder('Speak...');
    recognition.start();

    startSilenceTimer();
  };

  const stopListening = () => {
    setIsProcessing(true);
    recognition.stop();
  };

  recognition.onend = () => {
    setIsListening(false);
    setIsProcessing(false);
    setPlaceholder('Ask any legal question...');
    clearSilenceTimer();
  };

  const toggleListening = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
  };

  const startSilenceTimer = () => {
    silenceTimeoutRef.current = setTimeout(() => {
      stopListening();
    }, 3000);
  };

  const resetSilenceTimer = () => {
    clearTimeout(silenceTimeoutRef.current);
    startSilenceTimer();
  };

  const clearSilenceTimer = () => {
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
  };

  const handleBookmarkClick = (caseItem) => {
    setSelectedCase(caseItem);
    fetchBookmarkGroups();
    setOpenBookmarkModal(true);
  };

  const handleStatuteBookmark = (statute) => {
    setSelectedStatute(statute);
    fetchBookmarkGroups();
    setOpenStatuteModal(true);
  };

  const fetchBookmarkGroups = async () => {
    try {
      const userId = userInfo._id;
      const response = await fetch(
        `https://rg-law-gpt-be-auth2.azurewebsites.net/api/getUserBookmarkGroups?userId=${userId}`
      );

      const data = await response.json();
      if (response.ok) {
        // Extract all statute and case IDs from the groups
        const allStatuteIds = data.flatMap(
          (group) => group.statutes?.map((statute) => statute.id) || []
        );

        const allCaseIds = data.flatMap(
          (group) => group.cases?.map((caseItem) => caseItem.id) || []
        );

        setBookmarkGroups(data);
        setBookmarkedStatuteIds(new Set(allStatuteIds));
        setBookmarkedCaseIds(new Set(allCaseIds));
      } else {
        throw new Error(data.message || 'Failed to fetch bookmark groups.');
      }
    } catch (error) {
      console.error('Error fetching bookmark groups:', error);
      setSnackbarMessage({ type: 'error', text: error.message });
    }
  };

  const handleCreateBookmarkGroup = async () => {
    if (!bookmarkGroupName) return;

    try {
      const userId = userInfo._id;
      const response = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/createBookmarkGroup',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            groupName: bookmarkGroupName,
            caseId: selectedCase.id,
            caseNote,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Bookmark group created successfully!',
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase.id])
        );
        setOpenBookmarkModal(false);
        setBookmarkGroupName('');
        setCaseNote('');
      } else {
        throw new Error(data.message || 'Failed to create bookmark group.');
      }
    } catch (error) {
      setSnackbarMessage({ type: 'error', text: error.message });
    }
  };

  const handleCreateStatuteGroup = async () => {
    if (!bookmarkGroupName) return;
    try {
      const userId = userInfo._id;
      const response = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/createBookmarkGroup',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            groupName: bookmarkGroupName,
            statuteId: selectedStatute.id,
            statuteNote,
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Bookmark group created successfully!',
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.id])
        );
        setOpenStatuteModal(false);

        setOpenBookmarkModal(false);
        setBookmarkGroupName('');
        setStatuteNote('');
      } else {
        throw new Error(data.message || 'Failed to create bookmark group.');
      }
    } catch (error) {
      console.error('Error creating bookmark group:', error);
    }
  };

  const handleAddCaseToGroup = async () => {
    if (!selectedGroup) return;

    try {
      const userId = userInfo._id;
      const response = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/addCaseToGroup',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            groupId: selectedGroup,
            caseId: selectedCase.id,
            caseNote,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Case added to the selected group!',
        });
        setBookmarkedCaseIds(
          (prevIds) => new Set([...prevIds, selectedCase.id])
        );
        setOpenBookmarkModal(false);
        setCaseNote('');
      } else {
        throw new Error(data.message || 'Failed to add case to group.');
      }
    } catch (error) {
      setSnackbarMessage({ type: 'error', text: error.message });
    }
  };

  const findGroupIdByCaseId = (documents, caseId) => {
    // Find the document where the caseId exists in the 'cases' array
    const group = documents.find((doc) =>
      doc.cases.some((caseItem) => caseItem.id === caseId)
    );

    // Return the _id of the group or null if not found
    return group ? group._id : null;
  };

  const handleUnBookmark = async (caseId) => {
    try {
      fetchBookmarkGroups();
      const groupId = findGroupIdByCaseId(bookmarkGroups, caseId);
      console.log(groupId);
      const response = await fetch(deleteCaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the case is bookmarked
          caseId, // Case ID to delete
        }),
      });

      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Bookmark Removed!',
        });
        // Update the state by removing the case ID from bookmarked case IDs
        setBookmarkedCaseIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(caseId);
          return updatedIds;
        });
      } else {
        console.error('Error deleting bookmarked case');
      }
    } catch (error) {
      console.error('Error deleting bookmarked case:', error);
    }
  };

  const handleAddStatuteToGroup = async () => {
    if (!selectedGroup) return;
    try {
      const userId = userInfo._id;
      const response = await fetch(
        'https://rg-law-gpt-be-auth2.azurewebsites.net/api/addStatuteToGroup',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            groupId: selectedGroup,
            statuteId: selectedStatute.id,
            statuteNote, // Include notes when adding to a group
          }),
        }
      );
      const data = await response.json();
      if (response.ok) {
        setSnackbarMessage({
          type: 'success',
          text: 'Statute added to the selected group!',
        });
        setBookmarkedStatuteIds(
          (prevIds) => new Set([...prevIds, selectedStatute.id])
        );

        setOpenStatuteModal(false);
        setStatuteNote(''); // Clear notes after submission
      } else {
        throw new Error(data.message || 'Failed to add statute to group.');
      }
    } catch (error) {
      console.error('Error adding statute to group:', error);
    }
  };

  const findGroupIdByStatuteId = (documents, statuteId) => {
    const group = documents.find((doc) =>
      doc.statutes.some((statuteItem) => statuteItem.id === statuteId)
    );

    return group ? group._id : null;
  };

  const handleUnBookmarkStatute = async (statuteId) => {
    try {
      fetchBookmarkGroups();
      const groupId = findGroupIdByStatuteId(bookmarkGroups, statuteId);
      const response = await fetch(deleteCaseUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userInfo._id, // User ID from context or state
          groupId, // Group ID where the statute is bookmarked
          statuteId, // Statute ID to delete
        }),
      });

      if (response.ok) {
        // Update the state by removing the statute ID from bookmarked statute IDs
        setBookmarkedStatuteIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          updatedIds.delete(statuteId);
          return updatedIds;
        });
      } else {
        console.error('Error deleting bookmarked statute');
      }
    } catch (error) {
      console.error('Error deleting bookmarked statute:', error);
    }
  };

  useEffect(() => {
    // Fetch bookmark groups when the component mounts or when userInfo changes
    if (userInfo._id) {
      fetchBookmarkGroups();
    }
  }, [userInfo]);

  useEffect(() => {
    if (location.pathname === '/home') {
      setShowScrollButton(false);
      setMessages([]);
      setChatTitle('');
      setSelectedBook(null);
      setIsCaseLawClicked(false);
      dispatch({ type: 'CHAT_HISTORY_RESET' });
    }
  }, [location, dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const receivedQuery = queryParams.get('query');
    if (receivedQuery) {
      setInputMessage(receivedQuery);

      const handleMessageAndUpdateUrl = async () => {
        // Introduce a slight delay before clearing the URL
        setTimeout(() => {
          queryParams.delete('query');
          navigate({ search: queryParams.toString() }, { replace: true });
        }, 100);
        await handleSendMessage();
      };
      handleMessageAndUpdateUrl();
    }
  }, [location, handleSendMessage, navigate]);

  useEffect(() => {
    const container = chatContainerStartRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [history, messages]);

  useEffect(() => {
    if (successEdit) {
      updateChatTitle();
      dispatch({ type: 'EDIT_TITLE_RESET' });
    }
  }, [successEdit, finalChatId, userInfo, dispatch, updateChatTitle]);

  useEffect(() => {
    if (chatContainerEndRef.current) {
      chatContainerEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (isClicked || successDelete || !history) {
      setShowScrollButton(false);
      setMessages([]);
      setChatTitle('');
      setSelectedBook(null);
      setIsCaseLawClicked(false);
      dispatch({ type: 'DELETE_CHAT_RESET' });
      dispatch({ type: 'NEW_CHAT_RESET' });
    }
  }, [dispatch, isClicked, successDelete, history]);

  useEffect(() => {
    if (historyLoading) {
      setIsHistoryLoading(true);
    }
  }, [historyLoading]);

  useEffect(() => {
    //Add history to chat interface
    if (history) {
      setIsHistoryLoading(false);
      dispatch({ type: 'NEW_CHAT_RESET' });
      setChatTitle(history.chat_title);
      setFinalChatId(history.chat_id);
      setAlignment('balanced');
      setSelectedBook(null);
      const historyMessages = history.messages;
      if (historyMessages?.length > 0) {
        const firstContent = historyMessages[0].content;
        if (
          firstContent.startsWith('Retrieve') ||
          firstContent.startsWith('retrieve')
        ) {
          setAlignment('case law');
        }
      }
      if (historyMessages?.length > 0) {
        const firstContent = historyMessages[0].content;
        if (firstContent.startsWith('search')) {
          setAlignment('precise');
          setSelectedBook({
            code: 'COP',
            format:
              'For article 63A, clause 1 sub-clause a, the search query would be "63A.1.a"',
            full_name: 'THE CONSTITUTION OF THE ISLAMIC REPUBLIC OF PAKISTAN',
          });
        }
      }
      const mappedMessages =
        historyMessages?.length > 0 &&
        historyMessages.map((message) => {
          return {
            text: {
              response: message.response || message.content,
              references: message.references,
            },
            isUser: message.role === 'user',
          };
        });
      setMessages(mappedMessages);
    }
  }, [history, dispatch]);

  useEffect(() => {
    const newChatId = uuidv4();

    if (!history) {
      setFinalChatId(newChatId);
      setSelectedBook(null);
      setAlignment('balanced');
    }
  }, [history, dispatch, isClicked]);

  useEffect(() => {
    if (!isListening && inputMessage.trim() !== '' && isVoiceInput) {
      handleSendMessage();
      setIsVoiceInput(false);
    }
  }, [isListening, inputMessage, handleSendMessage, isVoiceInput]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 50px)',
          flex: 5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #ccc',
            width: '100%',
            minHeight: '50px',
          }}
        >
          <IconButton
            sx={{ display: { xs: 'flex', sm: 'none' } }}
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              gap: '0.5rem',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" sx={{ marginLeft: '2rem' }}>
              {chatTitle}
            </Typography>
          </Box>
          {history && (
            <Tooltip
              title="Export Chat"
              arrow
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: '#101936',
                  },
                  '& .MuiTooltip-arrow': {
                    color: '#101936',
                  },
                },
              }}
            >
              <IconButton
                variant="contained"
                onClick={handleExportChat}
                sx={{ marginRight: '1rem' }}
              >
                <IosShareIcon sx={{ color: '#2196f3' }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        {/* Conditional Rendering of the Chat Mode Buttons */}
        {messages.length === 0 && !selectedBook && !isHistoryLoading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '60%',
              width: '60%',
              marginLeft: 'auto',
              marginRight: 'auto',
              gap: '1rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <img
                src="/images/legalSearchLogo2.png"
                alt="product logo"
                style={{
                  maxWidth: '180px',
                  height: 'auto',
                  objectFit: 'contain',
                }}
              />
            </Box>
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                fontSize: '17px',
                width: '100%',
              }}
            >
              {location.pathname === '/home'
                ? 'How can I help you today?'
                : 'Choose a conversation style'}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                width: '100%',
              }}
            >
              {[
                { label: 'Hybrid Chat', value: 'balanced' },
                { label: 'Statute Chat', value: 'precise' },
                { label: 'Case Chat', value: 'case law' },
              ].map((mode, index) => (
                <Tooltip
                  key={index}
                  TransitionComponent={Zoom}
                  TransitionProps={{ timeout: 400 }}
                  title={tooltips[index]}
                  arrow
                  placement="bottom"
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        padding: '8px',
                        backgroundColor: '#101936',
                      },
                      '& .MuiTooltip-arrow': {
                        color: '#101936',
                      },
                    },
                  }}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 2],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <ChatModeBox
                      text={mode.label}
                      isSelected={mode.value === alignment}
                      onClick={() => handleChangeChat(mode.value)}
                    />
                  </Box>
                </Tooltip>
              ))}
            </Box>
          </Box>
        ) : (
          <div
            ref={chatContainerStartRef}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '16px',
              background: '#fff',
              height: '75%',
              overflowY: 'auto',
            }}
          >
            <style>
              {`
          div::-webkit-scrollbar {
            width: 5px;
          }
          div::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          div::-webkit-scrollbar-thumb {
            background: #101936;
            border-radius: 6px;
          }
        `}
            </style>
            {isHistoryLoading ? (
              <Box
                sx={{
                  height: '30%',
                  margin: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={24} />
              </Box>
            ) : (
              messages.length > 0 &&
              messages.map((message, messageIndex) => (
                <Box
                  key={uuidv4()}
                  sx={{
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'flex-start',
                    backgroundColor: message.isUser ? '#fff' : '#f5f5f5',
                    width: '95%',
                    borderRadius: '10px',
                  }}
                >
                  {message.isUser ? (
                    <Avatar
                      variant="circle"
                      sx={{
                        marginRight: '8px',
                        mt: 1,
                        ml: 4,
                        border: '1px solid #0BA4E0',
                      }}
                      src="/images/userImage.png"
                    />
                  ) : (
                    <Avatar
                      variant="circle"
                      sx={{
                        marginRight: '8px',
                        mt: 1,
                        ml: 4,
                        border: '1px solid #0BA4E0',
                        backgroundColor: '#101936',
                      }}
                      src="/images/lawgptt.png"
                    />
                  )}
                  <Typography
                    variant="body1"
                    textAlign="justify"
                    sx={{
                      padding: '1rem',
                      color: '#565454',
                    }}
                  >
                    <Markdown
                      components={{
                        ul: ({ ...props }) => (
                          <ul style={markdownStyles.ul} {...props} />
                        ),
                        ol: ({ ...props }) => (
                          <ol style={markdownStyles.ol} {...props} />
                        ),
                        li: ({ ...props }) => (
                          <li style={markdownStyles.li} {...props} />
                        ),
                        p: ({ ...props }) => (
                          <Typography
                            style={markdownStyles.p}
                            {...props}
                            component="p"
                          />
                        ),
                      }}
                    >
                      {message.text.response.replace(/\n\n/gi, '  \n')}
                    </Markdown>
                    {message.text.references?.filter(
                      (reference) => reference.type === 'statute'
                    ).length > 0 && (
                      <Typography
                        component="div"
                        sx={{
                          fontWeight: 'bold',
                          marginTop: 2,
                          marginBottom: '8px',
                          fontSize: '14px',
                        }}
                      >
                        Relevant Statutes:
                      </Typography>
                    )}
                    {message.text?.references
                      ?.filter((reference) => reference.type === 'statute')
                      .map((ref, refIndex) => (
                        <Box
                          key={uuidv4()}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            mb: '5px',
                            alignItems: 'center',
                          }}
                        >
                          <IconButton
                            onClick={() =>
                              handleStatutePreview(ref.html_content)
                            }
                            size="small"
                          >
                            <VisibilityIcon
                              fontSize="small"
                              sx={{ color: '#2196f3' }}
                            />
                          </IconButton>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <IconButton
                              sx={{
                                p: 0,
                                color: '#d2042d',
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                            >
                              {bookmarkedStatuteIds.has(ref.id) ? (
                                <>
                                  <Tooltip title="Unbookmark">
                                    <TurnedInIcon
                                      onClick={() =>
                                        handleUnBookmarkStatute(ref.id)
                                      }
                                      fontSize="small"
                                    />
                                  </Tooltip>
                                  {/* <Typography
                                    variant="body2"
                                    sx={{
                                      ml: 0.5,
                                      color: "#00509e",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                    onClick={() =>
                                      handleUnBookmarkStatute(ref.id)
                                    }
                                  >
                                    Bookmarked
                                  </Typography> */}
                                </>
                              ) : (
                                <>
                                  <Tooltip title="Bookmark">
                                    <TurnedInNotIcon
                                      sx={{
                                        '&:hover': {
                                          backgroundColor: 'transparent',
                                        },
                                      }}
                                      onClick={() => handleStatuteBookmark(ref)}
                                      fontSize="small"
                                    />
                                  </Tooltip>
                                  {/* <Typography
                                    variant="body2"
                                    sx={{
                                      ml: 0.5,
                                      color: "#00509e",
                                      cursor: "pointer",
                                      "&:hover": {
                                        textDecoration: "underline",
                                      },
                                    }}
                                    onClick={() => handleStatuteBookmark(ref)}
                                  >
                                    Bookmark
                                  </Typography> */}
                                </>
                              )}
                            </IconButton>
                          </Box>

                          <Typography
                            component="div"
                            sx={{
                              //fontWeight: 'bold',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            variant="subtitle2"
                          >
                            {formatRefLabel(ref.label)}
                          </Typography>
                          <Stack
                            sx={{
                              ml: 'auto',
                              display: 'flex',
                            }}
                            direction="row"
                            alignItems="center"
                          >
                            {/* <IconButton
                              onClick={() =>
                                handleCaseLawsClick(ref.cited_cases)
                              }
                              size="small"
                            >
                              <GavelOutlined
                                fontSize="small"
                                sx={{ color: "#ff9800" }}
                              />
                            </IconButton>
                            <Typography
                              onClick={() =>
                                handleCaseLawsClick(ref.cited_cases)
                              }
                              variant="body2"
                              sx={{
                                color: "#303f9f",
                                cursor: "pointer",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                            >
                              Case Laws
                            </Typography> */}
                          </Stack>
                        </Box>
                      ))}
                    {/* UI for the Case Law References */}
                    {message.text.references?.filter(
                      (reference) =>
                        reference.type !== 'case' &&
                        reference.type !== 'statute'
                    ).length > 0 && (
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          marginBottom: '6px',
                          marginTop: 2,
                          fontSize: '14px',
                        }}
                      >
                        Relevant Case Laws:
                      </Typography>
                    )}
                    {message.text.references
                      ?.filter(
                        (reference) =>
                          reference.type !== 'case' &&
                          reference.type !== 'statute'
                      )
                      .map((law, index) => (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: '12px',
                            }}
                          >
                            <Stack
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                              }}
                            >
                              <IconButton
                                onClick={() => handlePreviewClick(law)}
                                sx={{ p: 0, ml: 0.5, mr: 0.5 }}
                              >
                                <VisibilityIcon
                                  fontSize="small"
                                  sx={{ color: '#2196f3' }}
                                />
                              </IconButton>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <IconButton
                                  sx={{
                                    p: 0,
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                    },
                                  }}
                                >
                                  {bookmarkedCaseIds.has(law.id) ? (
                                    <>
                                      <Tooltip title="Unbookmark">
                                        <TurnedInIcon
                                          onClick={() =>
                                            handleUnBookmark(law.id)
                                          }
                                          sx={{ color: '#d2042d' }}
                                          fontSize="small"
                                        />
                                      </Tooltip>
                                      {/* <Typography
                                        variant="body2"
                                        sx={{
                                          ml: 0.5,
                                          color: "#303f9f",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                        onClick={() => handleUnBookmark(law.id)}
                                      >
                                        Bookmarked
                                      </Typography> */}
                                    </>
                                  ) : (
                                    <>
                                      <Tooltip title="Bookmark">
                                        <TurnedInNotIcon
                                          onClick={() =>
                                            handleBookmarkClick(law)
                                          }
                                          sx={{
                                            color: '#d2042d',
                                            '&:hover': {
                                              backgroundColor: 'transparent',
                                            },
                                          }}
                                          fontSize="small"
                                        />
                                      </Tooltip>
                                      {/* <Typography
                                        variant="body2"
                                        sx={{
                                          ml: 0.5,
                                          color: "#303f9f",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                        onClick={() => handleBookmarkClick(law)}
                                      >
                                        Bookmark
                                      </Typography> */}
                                    </>
                                  )}
                                </IconButton>
                              </Box>
                              <Typography
                                variant="subtitle2"
                                // sx={{ fontWeight: 'bold' }}
                              >
                                {formatCaseTitle(law?.title)}
                              </Typography>
                              <Stack
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  sx={{ fontWeight: 'bold' }}
                                  variant="subtitle2"
                                >
                                  ({law.label}) -
                                </Typography>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight="bold"
                                >
                                  {formatCourtName(law.court)}
                                </Typography>
                              </Stack>
                            </Stack>
                            {/* <Stack
                              sx={{
                                display: "flex",
                                flexDirection: isCaseLawClicked
                                  ? "column"
                                  : "row",
                                alignItems: "flex-start",
                                gap: "8px",
                              }}
                            ></Stack> */}
                            <Stack
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                              }}
                            >
                              {' '}
                              {/* <Stack
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                                gap={1}
                                onClick={() => handlePreviewClick(law)}
                              >
                                <IconButton sx={{ p: 0, ml: 0.5, mr: 0.5 }}>
                                  <VisibilityIcon
                                    fontSize="small"
                                    sx={{ color: "#2196f3" }}
                                  />
                                </IconButton>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    mr: 0.5,
                                    color: "#303f9f",
                                    cursor: "pointer",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  Preview
                                </Typography>
                              </Stack> */}
                            </Stack>
                          </Box>
                          <CaseLawPreviewModal
                            open={openPreview}
                            onClose={() => {
                              setOpenPreview(false);
                              dispatch({ type: 'CASE_DETAIL_RESET' });
                            }}
                          />
                        </>
                      ))}
                    {message.text.references?.filter(
                      (reference) => reference.type === 'case'
                    ).length > 0 && (
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          marginBottom: '6px',
                          marginTop: 2,
                          fontSize: '14px',
                        }}
                      >
                        Relevant Case Laws:
                      </Typography>
                    )}
                    {message.text.references
                      ?.filter((reference) => reference.type === 'case')
                      ?.map((law, index) => (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: '12px',
                            }}
                          >
                            <Stack
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <IconButton
                                onClick={() => handlePreviewClick(law)}
                                sx={{ p: 0, ml: 0.8, mr: '4px' }}
                              >
                                <VisibilityIcon
                                  fontSize="small"
                                  sx={{ color: '#2196f3' }}
                                />
                              </IconButton>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <IconButton
                                  sx={{
                                    p: 0,
                                    '&:hover': {
                                      backgroundColor: 'transparent',
                                    },
                                  }}
                                >
                                  {bookmarkedCaseIds.has(law.id) ? (
                                    <>
                                      <Tooltip title="Unbookmark">
                                        <TurnedInIcon
                                          onClick={() =>
                                            handleUnBookmark(law.id)
                                          }
                                          sx={{ color: '#d2042d' }}
                                          fontSize="small"
                                        />
                                      </Tooltip>
                                      {/* <Typography
                                        variant="body2"
                                        sx={{
                                          ml: 0.5,
                                          color: "#303f9f",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                        onClick={() => handleUnBookmark(law.id)}
                                      >
                                        Bookmarked
                                      </Typography> */}
                                    </>
                                  ) : (
                                    <>
                                      <Tooltip title="Bookmark">
                                        <TurnedInNotIcon
                                          onClick={() =>
                                            handleBookmarkClick(law)
                                          }
                                          sx={{
                                            color: '#d2042d',
                                            '&:hover': {
                                              backgroundColor: 'transparent',
                                            },
                                          }}
                                          fontSize="small"
                                        />
                                      </Tooltip>
                                      {/* <Typography
                                        variant="body2"
                                        sx={{
                                          ml: 0.5,
                                          color: "#303f9f",
                                          cursor: "pointer",
                                          "&:hover": {
                                            textDecoration: "underline",
                                          },
                                        }}
                                        onClick={() => handleBookmarkClick(law)}
                                      >
                                        Bookmark
                                      </Typography> */}
                                    </>
                                  )}
                                </IconButton>
                              </Box>
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  //fontWeight: 'bold',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '50%',
                                }}
                              >
                                {formatCaseTitle(law.title)}
                              </Typography>
                              <Stack
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: 0.4,
                                  ml: 0.8,
                                }}
                              >
                                <Typography
                                  variant="subtitle2"
                                  fontWeight="bold"
                                >
                                  {`(${law.label})`}
                                </Typography>
                                <Typography
                                  sx={{ fontWeight: 'bold' }}
                                  component="span"
                                  variant="subtitle2"
                                >
                                  - {formatCourtName(law.court)}
                                </Typography>
                              </Stack>
                            </Stack>

                            {/* <Stack
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                              onClick={() => handlePreviewClick(law)}
                            >
                              <IconButton sx={{ p: 0, ml: 0.8, mr: "4px" }}>
                                <VisibilityIcon
                                  fontSize="small"
                                  sx={{ color: "#2196f3" }}
                                />
                              </IconButton>
                              <Typography
                                variant="body2"
                                sx={{
                                  mr: 0.5,
                                  color: "#303f9f",
                                  cursor: "pointer",
                                  "&:hover": {
                                    textDecoration: "underline",
                                  },
                                }}
                              >
                                Preview
                              </Typography>
                            </Stack> */}
                          </Box>
                          <CaseLawPreviewModal
                            open={openPreview}
                            onClose={() => {
                              setOpenPreview(false);
                              dispatch({ type: 'CASE_DETAIL_RESET' });
                            }}
                          />
                        </>
                      ))}
                    {!message.isUser && (
                      <Stack flexDirection="row" sx={{ marginTop: '10px' }}>
                        <Tooltip
                          title={isCopied ? 'Copied' : 'Copy'}
                          arrow
                          PopperProps={{
                            sx: {
                              '& .MuiTooltip-tooltip': {
                                backgroundColor: '#101936',
                              },
                              '& .MuiTooltip-arrow': {
                                color: '#101936',
                              },
                            },
                          }}
                        >
                          <IconButton
                            sx={{ color: isCopied ? '#4caf50' : '#2196f3' }}
                            onClick={() =>
                              handleCopyClick(message.text.response)
                            }
                          >
                            {isCopied ? (
                              <CheckCircleOutlineIcon fontSize="small" />
                            ) : (
                              <ContentCopyIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Bad Response"
                          arrow
                          PopperProps={{
                            sx: {
                              '& .MuiTooltip-tooltip': {
                                backgroundColor: '#101936',
                              },
                              '& .MuiTooltip-arrow': {
                                color: '#101936',
                              },
                            },
                          }}
                        >
                          <IconButton
                            disabled={feedbackGiven[messageIndex]}
                            sx={{ color: '#d32f2f' }}
                            onClick={() =>
                              handleFeedbackClick(
                                message.text.response,
                                message.text.references,
                                messageIndex
                              )
                            }
                          >
                            <ThumbDownOffAltIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    )}
                  </Typography>
                </Box>
              ))
            )}
            {isLoading && (
              <Box display="flex" flexDirection="column" width="85%">
                <Skeleton width="85%" />
                <Skeleton width="85%" animation="wave" />
                <Skeleton width="80%" animation={false} />
              </Box>
            )}
            <div ref={chatContainerEndRef} />
          </div>
        )}
        {showScrollButton && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <Fab
              size="small"
              sx={{
                color: 'white',
                backgroundColor: '#303F9F',
                '&:hover': {
                  boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                  color: 'white',
                  backgroundColor: '#303F9F',
                },
              }}
              onClick={scrollToBottom}
            >
              <ArrowDownwardIcon />
            </Fab>
          </Box>
        )}
        <AlertDialog
          open={openAlertDialog}
          handleClose={handleCloseAlertDialog}
        />
        <Feedback
          open={openFeedbackDialog}
          modelResponse={response}
          feedbackQuery={feedbackQuery}
          references={feedbackRefs}
          handleClose={handleCloseFeedbackDialog}
          messageIndex={messageIndex}
          feedbackGiven={handleFeedbackGiven}
        />
        <BookListingDialog
          open={openListingDialog}
          handleClose={handleCloseListingDialog}
          handleBookChange={updateSelectedBook}
        />
        <Box sx={{ flexGrow: 1 }} />
        {/* {alignment === 'precise' && selectedBook && (
          <Stack
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '79%',
              columnGap: 1,
            }}
          >
            <Button
              sx={{
                borderRadius: 2,
                color: '#003D78',
                textTransform: 'none',
              }}
              variant="outlined"
              onClick={handlePreciseChat}
            >
              <ChangeCircleIcon sx={{ marginRight: '0.3rem' }} />
              Change Book
            </Button>
          </Stack>
        )} */}
        {/* Container for Format text and Input field */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '0.5rem',
            marginTop: '3px',
          }}
        >
          {/* {selectedBook && (
            <Typography
              variant="caption"
              sx={{
                display: 'flex',
                width: '58%',
                borderRadius: '5px',
                paddingLeft: '0.5rem',
                backgroundColor: '#F5F5F5',
              }}
            >
              Input Format: {selectedBook?.format}
            </Typography>
          )} */}
          {history || messages?.length > 0 ? (
            <>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "59%",
                  borderRadius: 8,
                  border: "1px solid gray",
                  padding: "8px",
                  marginBottom: "16px",
                  boxShadow: 3,
                }}
              > */}
              <TextField
                sx={{
                  whiteSpace: 'normal',
                  width: '59%',
                  boxShadow: 3,
                  borderRadius: 8,
                  marginBottom: '4px',
                  color: '#003D78',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'gray',
                    },
                    '&:hover fieldset': {
                      borderColor: 'gray',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'gray',
                    },
                  },
                  '& .MuiInputLabel-root': {
                    color: '#003D78',
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: '#1F2A49',
                  },
                }}
                variant="outlined"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                value={inputMessage}
                placeholder={placeholder}
                InputProps={{
                  sx: { borderRadius: 8, p: 0 },
                  endAdornment: (
                    <>
                      <IconButton
                        sx={{
                          color: isListening ? '#FF4081' : '#303f9f',
                          animation: isListening
                            ? 'pulse 1.5s infinite'
                            : 'none',
                        }}
                        variant="contained"
                        onClick={toggleListening}
                        disabled={isProcessing}
                      >
                        <KeyboardVoice />
                      </IconButton>
                      <Stack
                        sx={{
                          paddingLeft: 1,
                          paddingRight: 1,
                          height: '100%',
                          borderRadius: '0px 64px 64px 0px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          backgroundColor: '#303f9f',
                        }}
                      >
                        <IconButton
                          sx={{ color: '#fff' }}
                          variant="contained"
                          onClick={handleSendMessage}
                        >
                          {isLoading ? (
                            <CircularProgress color="inherit" size={24} />
                          ) : (
                            <SendIcon />
                          )}
                        </IconButton>
                      </Stack>
                    </>
                  ),
                }}
              />
              {/* <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginLeft: "4px",
                  marginRight: "4px",
                  gap: 1,
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                > */}
              {/* <IconButton
                  variant="contained"
                  onClick={handleSendMessage}
                  sx={{
                    backgroundColor: "#303f9f",
                    "&:hover": { backgroundColor: "#303f9f" },
                  }}
                >
                  {isLoading ? (
                    <CircularProgress sx={{ color: "white" }} size={24} />
                  ) : (
                    <SendIcon
                      sx={{
                        color: "white",
                      }}
                    />
                  )}
                </IconButton> */}
              {/* </Stack>
              </Stack> */}
              {/* </Box> */}
              <Stack
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 1,
                  justifyContent: 'center',
                  mb: 1,
                }}
              >
                <Button
                  size="small"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                    border: '1px solid gray',
                    color: alignment === 'balanced' ? 'white' : '#36454f',
                    backgroundColor:
                      alignment === 'balanced' ? '#303f9f' : 'white',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor:
                        alignment === 'balanced' ? '#303f9f' : 'white',
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                      color: alignment === 'balanced' ? 'white' : 'black',
                    },
                  }}
                  startIcon={
                    <AccountTreeOutlinedIcon
                      sx={{
                        color: alignment === 'balanced' ? 'white' : '#3c50b1',
                        backgroundColor:
                          alignment === 'balanced' ? '#303f9f' : 'white',
                      }}
                    />
                  }
                  onClick={() => handleButtonClick('balanced')}
                >
                  Hybrid Chat
                </Button>
                <Button
                  size="small"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                    border: '1px solid gray',
                    color: alignment === 'precise' ? 'white' : '#36454f',
                    backgroundColor:
                      alignment === 'precise' ? '#303f9f' : 'white',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor:
                        alignment === 'precise' ? '#303f9f' : 'white',
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                      color: alignment === 'precise' ? 'white' : 'black',
                    },
                  }}
                  startIcon={
                    <MenuBookOutlinedIcon
                      sx={{
                        color: alignment === 'precise' ? 'white' : '#3c50b1',
                        backgroundColor:
                          alignment === 'precise' ? '#303f9f' : 'white',
                      }}
                    />
                  }
                  onClick={() => handleButtonClick('precise')}
                >
                  Statute Chat
                </Button>
                <Button
                  size="small"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '8px',
                    border: '1px solid gray',
                    color: alignment === 'case law' ? 'white' : '#36454f',
                    backgroundColor:
                      alignment === 'case law' ? '#303f9f' : 'white',
                    textTransform: 'none',
                    '&:hover': {
                      backgroundColor:
                        alignment === 'case law' ? '#303f9f' : 'white',
                      boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                      color: alignment === 'case law' ? 'white' : 'black',
                    },
                  }}
                  startIcon={
                    <PlagiarismOutlinedIcon
                      sx={{
                        color: alignment === 'case law' ? 'white' : '#3c50b1',
                        backgroundColor:
                          alignment === 'case law' ? '#303f9f' : 'white',
                      }}
                    />
                  }
                  onClick={() => handleButtonClick('case law')}
                >
                  Case Chat
                </Button>
              </Stack>
            </>
          ) : location.pathname === '/home' ? (
            <></>
          ) : (
            <TextField
              sx={{
                whiteSpace: 'normal',
                width: '59%',
                boxShadow: 3,
                borderRadius: 8,
                marginBottom: '16px',
                color: '#003D78',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'gray',
                  },
                  '&:hover fieldset': {
                    borderColor: 'gray',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'gray',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#003D78',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#1F2A49',
                },
              }}
              variant="outlined"
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={inputMessage}
              //label={selectedBook?.full_name}
              placeholder={placeholder}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                sx: { borderRadius: 8, p: 0 },
                endAdornment: (
                  <>
                    <IconButton
                      sx={{
                        color: isListening ? '#FF4081' : '#303f9f',
                        animation: isListening ? 'pulse 1.5s infinite' : 'none',
                      }}
                      variant="contained"
                      onClick={toggleListening}
                      disabled={isProcessing}
                    >
                      <KeyboardVoice />
                    </IconButton>
                    <Stack
                      sx={{
                        paddingLeft: 1,
                        paddingRight: 1,
                        height: '100%',
                        borderRadius: '0px 64px 64px 0px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        backgroundColor: '#303f9f',
                      }}
                    >
                      <IconButton
                        sx={{ color: '#fff' }}
                        variant="contained"
                        onClick={handleSendMessage}
                      >
                        {isLoading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          <SendIcon />
                        )}
                      </IconButton>
                    </Stack>
                  </>
                ),
              }}
            />
          )}
        </Box>
        <ToastContainer />
      </Box>
      {/* {statuteRelevantCasesLoading && (
        <Box sx={{ flexDirection: 'column' }} display="flex" width={300}>
          <CircularProgress
            sx={{ position: 'relative', top: '20%', left: '45%' }}
            size={24}
          />
        </Box>
      )} */}
      {isCaseLawClicked && (
        <Box
          sx={{
            flexDirection: 'column',
            overflowY: 'auto',
            maxHeight: '100vh',
            borderLeft: '1px solid #ccc',
          }}
          display="flex"
          width={300}
        >
          <CaseLawsSidebar
            cases={statuteRelevantCases}
            onClose={handleCloseCaseLawsSidebar}
          />
        </Box>
      )}

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        fullWidth
        fullScreen={fullscreen}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title="Full Screen">
            <IconButton onClick={handleFullscreenToggle}>
              {fullscreen ? (
                <FullscreenExitIcon sx={{ color: '#36454f' }} />
              ) : (
                <FullscreenIcon sx={{ color: '#36454f' }} />
              )}
            </IconButton>
          </Tooltip>
          <IconButton
            sx={{ mr: 2 }}
            edge="end"
            color="inherit"
            onClick={handleCloseModal}
          >
            <CancelIcon color="error" />
          </IconButton>
        </Stack>
        <DialogContent dividers>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
              '& p': {
                margin: 0,
              },
              '& h1': {
                fontFamily: 'Roboto, Arial, sans-serif',
                margin: '16px 0',
              },
              '& i': {
                fontStyle: 'italic',
              },
              '& a': {
                color: '#303f9f',
                textDecoration: 'none',
              },
            }}
            component="div"
            dangerouslySetInnerHTML={{ __html: statuteContentWithStyles }}
          />
        </DialogContent>
      </Dialog>
      <Modal
        open={openBookmarkModal}
        onClose={() => setOpenBookmarkModal(false)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box
          sx={{
            width: 400,
            margin: 'auto',
            mt: '10%',
            p: 2,
            borderRadius: 2,
            border: '1px solid grey',
            bgcolor: 'white',
            boxShadow: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h6">Bookmark Case</Typography>
            <IconButton
              color="error"
              onClick={() => setOpenBookmarkModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Dropdown for selecting an existing group or creating a new group */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="select-bookmark-group-label">
              Select or Create Group
            </InputLabel>
            <Select
              labelId="select-bookmark-group-label"
              value={selectedGroup}
              label="Select or Create Group"
              onChange={(e) => {
                setSelectedGroup(e.target.value);
                if (e.target.value === 'new') {
                  setIsCreatingNewGroup(true);
                } else {
                  setIsCreatingNewGroup(false);
                }
              }}
            >
              <MenuItem sx={{ color: '#303f9f' }} value="new">
                Create New Group
              </MenuItem>
              {bookmarkGroups.map((group) => (
                <MenuItem
                  sx={{
                    maxWidth: '400px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'block',
                  }}
                  key={group._id}
                  value={group._id}
                >
                  {group.groupName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Input field for creating a new group, only visible if 'Create New Group' is selected */}
          {isCreatingNewGroup && (
            <TextField
              fullWidth
              label="New Group Name"
              variant="outlined"
              value={bookmarkGroupName}
              onChange={(e) => setBookmarkGroupName(e.target.value)}
              sx={{ mb: 2 }}
            />
          )}

          {/* Text input for adding a note */}
          <TextField
            label="Add a Note"
            fullWidth
            sx={{ mb: 2 }}
            value={caseNote}
            onChange={(e) => setCaseNote(e.target.value)}
            multiline
            rows={3}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              onClick={() => setOpenBookmarkModal(false)}
              color="error"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            {/* Conditional rendering for buttons based on selection */}
            {isCreatingNewGroup ? (
              <Button
                variant="contained"
                onClick={() => {
                  handleCreateBookmarkGroup();
                  setOpenBookmarkModal(false);
                }}
                disabled={!bookmarkGroupName}
                sx={{ bgcolor: '#003D78' }}
              >
                Add
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedGroup}
                onClick={() => {
                  handleAddCaseToGroup();
                  setOpenBookmarkModal(false);
                }}
                sx={{ bgcolor: '#003D78' }}
              >
                Add
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={openStatuteModal}
        onClose={() => setOpenStatuteModal(false)}
      >
        <DialogTitle sx={{ mb: 2 }}>Bookmark Statute</DialogTitle>
        <DialogContent sx={{ width: 400, overflow: 'visible' }}>
          <TextField
            sx={{ mt: 1 }}
            label="Add to existing group"
            select
            fullWidth
            value={selectedGroup}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === 'create-new') {
                setBookmarkGroupName('');
              }
              setSelectedGroup(selectedValue);
            }}
          >
            <MenuItem sx={{ color: '#303f9f' }} value="create-new">
              Create New Group
            </MenuItem>
            {bookmarkGroups.map((group) => (
              <MenuItem
                sx={{
                  maxWidth: '400px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                key={group._id}
                value={group._id}
              >
                {group.groupName}
              </MenuItem>
            ))}
          </TextField>

          {/* Conditionally render the input field if "Create New Group" is selected */}
          {selectedGroup === 'create-new' && (
            <TextField
              label="New Group Name"
              fullWidth
              value={bookmarkGroupName}
              onChange={(e) => setBookmarkGroupName(e.target.value)}
              sx={{ mt: 2 }}
            />
          )}

          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            multiline
            rows={3}
            value={statuteNote}
            onChange={(e) => setStatuteNote(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenStatuteModal(false)} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ mr: 2 }}
            onClick={
              selectedGroup === 'create-new'
                ? handleCreateStatuteGroup
                : handleAddStatuteToGroup
            }
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(ChatInterface);

ChatInterface.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
};
