import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Grid,
  Checkbox,
  Card,
  TextField,
  ListItem,
  List,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import Markdown from 'react-markdown';

const cleanHTML = (html, removeClass, adjustClasses) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const elementsToRemove = doc.querySelectorAll(`.${removeClass}`);
  elementsToRemove.forEach((element) => element.remove());

  adjustClasses.forEach((className) => {
    const elementsToAdjust = doc.querySelectorAll(`.${className}`);
    elementsToAdjust.forEach((element) => {
      element.style.height = 'calc(100vh - 50px)';
      element.style.maxHeight = 'calc(100vh - 50px)';
    });
  });

  return doc.body.innerHTML;
};

const checkboxItems = [
  { label: 'Facts', color: '#ffec94' },
  { label: 'Issues', color: '#ffaeae' },
  { label: "Petitioner's Argument", color: '#ccffbb' },
  { label: "Respondent's Arguments", color: '#cccc99' },
  { label: 'Analysis of the Law', color: '#56baec' },
  { label: 'Precedent Analysis', color: '#b4d8e7' },
  { label: "Court's Reasoning", color: '#fce6e6' },
  { label: 'Conclusion', color: '#c0bcb6' },
];

const generateId = (text) =>
  text
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '');

const renderMarkdownContent = (content) => {
  const lines = content?.split('\n');
  const elements = [];

  lines?.forEach((line, index) => {
    if (line.startsWith('# ')) {
      // Generate a unique ID for the heading
      const headingText = line.replace('# ', '');
      const headingId = generateId(headingText);
      // Heading logic
      elements.push(
        <Typography
          id={headingId}
          key={index}
          component="h2"
          gutterBottom
          sx={{
            marginBottom: '15px',
            fontWeight: 'bold',
            fontSize: '16px',
            paddingBottom: '8px',
            color: '#303f9f',
            borderBottom: '1px solid #e6e6e6',
          }}
        >
          {headingText}
        </Typography>
      );
    } else if (line.startsWith('**')) {
      // Bold text logic
      const [label, text] = line.split('**').filter(Boolean);
      elements.push(
        <Typography
          key={index}
          variant="body1"
          component="p"
          sx={{
            textAlign: 'justify',
          }}
        >
          <strong>{label}</strong>
          {text}
        </Typography>
      );
    } else if (line.startsWith('- ')) {
      // List item logic
      const listItem = (
        <ListItem
          key={elements.length}
          sx={{
            display: 'list-item',
            pl: 0,
            mb: 0,
          }}
        >
          <Typography variant="body1">{line.replace('- ', '')}</Typography>
        </ListItem>
      );
      const lastElement = elements[elements.length - 1];
      // Check if last element is a list, then append to it
      if (Array.isArray(lastElement)) {
        lastElement.push(listItem);
      } else {
        elements.push([listItem]);
      }
    } else {
      // Regular paragraph logic for everything else
      elements.push(
        <Typography
          key={index}
          variant="body1"
          component="p"
          paragraph
          sx={{
            textAlign: 'justify',
          }}
        >
          {line}
        </Typography>
      );
    }
  });

  return elements.map((element, idx) =>
    Array.isArray(element) ? (
      <List
        key={idx}
        dense
        sx={{
          listStyleType: 'decimal',
          pl: 4,
          mb: 0,
        }}
      >
        {element}
      </List>
    ) : (
      element
    )
  );
};

const formatCourtName = (courtName) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(courtName, 'text/html');

  const formatTextContent = (name) => {
    return name
      .toLowerCase()
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const formatJudgeName = (names) => {
  if (!names || names.length === 0) return '';

  // Check if names is an array, if not treat it as a single name
  if (!Array.isArray(names)) {
    names = [names];
  }

  // Convert each name to title case and join them with commas
  const titleCaseNames = names
    .map((name) =>
      name
        .toLowerCase()
        .split(' ')
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
    )
    .join(', ');

  return titleCaseNames;
};

const formatCaseTitle = (title) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(title, 'text/html');

  const formatTextContent = (text) => {
    return text
      .split('Honorable')[0]
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const walkNodes = (node) => {
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        child.nodeValue = formatTextContent(child.nodeValue);
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        walkNodes(child);
      }
    });
  };

  walkNodes(doc.body);

  return doc.body.innerHTML;
};

const CaseDetail = ({ selectedSection }) => {
  const caseDetail = useSelector((state) => state.caseDetail);
  const { caseItem } = caseDetail;

  const [checkedItems, setCheckedItems] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [matches, setMatches] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [caseDetailHeadings, setCaseDetailHeadings] = useState([]);
  const [highlightedElements, setHighlightedElements] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const highlights = document.querySelectorAll('.highlight');
    setHighlightedElements(highlights);

    if (highlights.length > 0) {
      highlights.forEach((el, index) => {
        if (index === currentIndex) {
          el.style.backgroundColor = 'yellow';
          el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          el.style.backgroundColor = 'orange';
        }
      });
    }
  }, [searchTerm, currentIndex]);

  const handleNextMatch = () => {
    if (highlightedElements.length > 0) {
      const nextIndex = (currentIndex + 1) % highlightedElements.length;
      setCurrentIndex(nextIndex);
    }
  };

  const caseDetailsFromCosmos = useSelector(
    (state) => state.caseDetailsFromCosmos
  );
  const { loading: loadingAdditionalDetails, caseItem: additionalCaseDetails } =
    caseDetailsFromCosmos;

  useEffect(() => {
    const content = additionalCaseDetails?.generatedCaseDetails;

    if (typeof content === 'string') {
      const extractedHeadings = [...content.matchAll(/^##\s(.+)/gm)].map(
        (match) =>
          match[1]
            .trim()
            .toLowerCase()
            .replace(/['\s]+/g, '-')
      );
      setCaseDetailHeadings(extractedHeadings);
    }
  }, [additionalCaseDetails]);

  const handleCheckboxChange = (label) => {
    setCheckedItems((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const cleanedHeadNotesHTML = cleanHTML(
    caseItem?.Head_Notes_HTML,
    'modal-header',
    [
      'scroll-wrapper',
      'modal-body',
      'case_description_modal_body',
      'scroll-content',
      'scroll-scrolly_visible',
    ]
  );

  const headNotesRef = useRef(null);

  const highlightText = (html, search) => {
    if (!search) return html;

    // Escape special characters in the search term
    const escapedTerm = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedTerm})`, 'gi');

    // Function to highlight text recursively
    const highlightNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const highlightedText = node.textContent.replace(
          regex,
          '<span class="highlight" style="background-color: orange; font-weight: bold;">$1</span>'
        );
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = highlightedText;
        return tempDiv.innerHTML;
      }

      if (node.nodeType === Node.ELEMENT_NODE) {
        const children = Array.from(node.childNodes)
          .map(highlightNode)
          .join('');
        const element = document.createElement(node.nodeName.toLowerCase());
        Array.from(node.attributes).forEach((attr) => {
          element.setAttribute(attr.name, attr.value);
        });
        element.innerHTML = children;
        return element.outerHTML;
      }

      return '';
    };

    // Parse the input HTML and highlight matches
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const highlightedHtml = Array.from(tempDiv.childNodes)
      .map(highlightNode)
      .join('');

    return highlightedHtml;
  };

  const getContentAfterHeadings = (html) => {
    // Parse the HTML string to a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Find elements that could contain "ORDER" or "JUDGEMENT"
    const allElements = Array.from(doc.querySelectorAll('h1, p, b'));

    // Look for "ORDER" or "JUDGEMENT" inside these elements
    const targetElement = allElements.find(
      (el) =>
        el.textContent.trim().toUpperCase() === 'ORDER' ||
        el.textContent.trim().toUpperCase() === 'JUDGEMENT' ||
        el.textContent.trim().toUpperCase() === 'JUDGMENT'
    );

    // Create an array to store the content after "ORDER" or "JUDGEMENT"
    let contentAfterHeadings = [];

    if (targetElement) {
      // Traverse the sibling elements after the found target element
      let nextElement = targetElement.closest('p, h1, b').nextElementSibling;
      while (nextElement) {
        contentAfterHeadings.push(nextElement.outerHTML);
        nextElement = nextElement.nextElementSibling;
      }
    }

    // Join the content and return
    return contentAfterHeadings.join('');
  };

  const caseDescriptionWithStyles = `
  <style>
    .MsoNormal {
      font-family: 'Roboto', Helvetica, Arial, sans-serif !important;
      font-size: 14px;
      text-align: 'justify';
    }
     .MsoNormal b {
      color: #303f9f;
    }
  </style>
  ${highlightText(
    getContentAfterHeadings(
      caseItem?.Case_Description_HTML ||
        caseItem?.metadata.Case_Description_HTML
    ),
    searchTerm
  )}
`;

  const getHighlightedHtml = () => {
    if (!searchTerm || matches.length === 0) return caseDescriptionWithStyles;

    const regex = new RegExp(searchTerm, 'gi');
    let currentIndex = -1;

    return caseDescriptionWithStyles.replace(regex, (match) => {
      currentIndex += 1;
      const isActive = currentIndex === activeIndex;
      const highlightClass = isActive ? 'highlight active' : 'highlight';
      return `<span class="${highlightClass}">${match}</span>`;
    });
  };

  const scrollToMatch = (index) => {
    const matchElement = document.getElementById(`match-${index}`);
    if (matchElement) {
      matchElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  // const handleNextMatch = () => {
  //   const nextIndex = (activeIndex + 1) % matches.length;
  //   setActiveIndex(nextIndex);
  //   scrollToMatch(nextIndex);
  // };

  const caseMetaData = [
    {
      label: 'Title',
      value: formatCaseTitle(caseItem?.Title || caseItem?.metadata.Title),
    },
    {
      label: 'Citation',
      value: caseItem?.Citation_Name || caseItem?.metadata.Citation_Name,
    },
    {
      label: 'Court',
      value: formatCourtName(caseItem?.Court || caseItem?.metadata.Court),
    },
    {
      label: 'Judge',
      value: formatJudgeName(
        caseItem?.Judge_Name || caseItem?.metadata.Judge_Name
      ),
    },
    {
      label: 'Appellant',
      value: formatCaseTitle(caseItem?.Title).split('Versus')[0],
    },
    {
      label: 'Respondents',
      value: formatCaseTitle(caseItem?.Title).split('Versus')[1],
    },
  ];

  const filteredMetaData = caseMetaData.filter((detail) => detail.value);

  useEffect(() => {
    // Track the previously selected section
    let previousSection = null;

    // Only apply for specific section IDs
    if (
      selectedSection === 'case-information' ||
      selectedSection === 'legal-provisions' ||
      selectedSection === 'case-summary' ||
      selectedSection === 'courts-findings' ||
      selectedSection === 'case-law-references' ||
      caseDetailHeadings.some((heading) => heading === selectedSection)
    ) {
      const section = document.getElementById(selectedSection);

      // Clear the background color of the previously selected section
      if (previousSection) {
        previousSection.style.backgroundColor = '';
      }

      // Apply background color to the currently selected section
      if (section) {
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        section.style.backgroundColor = 'yellow';

        // Update the previous section to the current one
        previousSection = section;
      }
    }

    // Clean up: Reset the background color when the component unmounts
    return () => {
      if (previousSection) {
        previousSection.style.backgroundColor = '';
      }
    };
  }, [selectedSection]);

  // Scroll to the active element
  useEffect(() => {
    const activeElement = document.querySelector('.highlight.active');
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [activeIndex]);

  useEffect(() => {
    if (headNotesRef.current) {
      const elements = headNotesRef.current.querySelectorAll(
        '.scroll-wrapper, .modal-body, .case_description_modal_body, .scroll-content, .scroll-scrolly_visible'
      );
      elements.forEach((element) => {
        element.style.height = 'calc(100vh - 50px)';
        element.style.maxHeight = 'calc(100vh - 50px)';
      });
    }
  }, [cleanedHeadNotesHTML]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 50px)',
        flex: 5,
        flexGrow: 1,
        // p: 1,
        boxSizing: 'border-box',
        bgcolor: '#f5f5f5',
        overflowY: 'auto',
        '::-webkit-scrollbar': {
          width: '5px',
        },
        '::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#101936',
          borderRadius: '6px',
        },
      }}
    >
      {selectedSection === 'Original Judgement' && (
        <React.Fragment>
          {/* Case Tags Card */}
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1000,
              backgroundColor: '#fff',
            }}
          >
            <Card
              sx={{
                overflow: 'visible',
                display: 'flex',
                flexDirection: 'column',
                p: '8px',
                marginTop: 1,
                bgcolor: '#fff',
                boxShadow: '1px 2px 6px 0px #00000020',
                borderRadius: '8px',
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  display: 'flex',
                  fontWeight: 'bold',
                  marginBottom: '6px',
                }}
              >
                Select the following parts of the judgement
              </Typography>
              <Grid container spacing={2}>
                {checkboxItems.map(({ label, color }) => (
                  <Grid item xs={3} key={label}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        bgcolor: color,
                        p: '5px',
                        borderRadius: '4px',
                      }}
                    >
                      <Checkbox
                        size="small"
                        checked={checkedItems[label] || false}
                        onChange={() => handleCheckboxChange(label)}
                        sx={{ p: 0, bgcolor: 'white', borderRadius: 0 }}
                      />
                      <Typography
                        sx={{
                          color: '#000',
                          ml: '4px',
                          fontWeight: 'bold',
                          fontSize: '13px',
                        }}
                      >
                        {label}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Card>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                bgcolor: '#f5f5f5',
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{
                  backgroundColor: 'white',
                  margin: '8px 8px 8px 8px',
                  borderRadius: '24px',
                  '& .MuiInputBase-root': {
                    height: '40px',
                    fontSize: '14px',
                  },
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '24px',
                  },
                }}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />
              <IconButton
                onClick={handleNextMatch}
                disabled={highlightedElements.length === 0}
              >
                <ExpandMore />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              overflowY: 'auto',
              flexShrink: 0,
              bgcolor: '#ffffff',
              p: '12px 12px',
              m: 1,
              borderRadius: '8px',
              '::-webkit-scrollbar': {
                width: '5px',
              },
              '::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '::-webkit-scrollbar-thumb': {
                background: '#101936',
                borderRadius: '6px',
              },
            }}
          >
            {filteredMetaData.map((detail, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: index % 2 === 0 ? '#f5f5f5' : '#fff',
                  paddingLeft: '5px',
                  marginBottom: '8px',
                }}
              >
                <Typography sx={{ fontSize: '14px' }}>
                  <span style={{ fontWeight: 'bold' }}>{detail.label}:</span>{' '}
                  <span>{detail.value}</span>
                </Typography>
              </Box>
            ))}
            <Box
              dangerouslySetInnerHTML={{
                __html: caseDescriptionWithStyles,
              }}
            />
          </Box>
        </React.Fragment>
      )}
      {(selectedSection === 'Case Details' ||
        caseDetailHeadings?.some((heading) => heading === selectedSection)) && (
        <React.Fragment>
          {loadingAdditionalDetails ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '24px',
                width: '100%',
              }}
            >
              <CircularProgress
                size={20}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                overflowY: 'auto',
                flexShrink: 0,
                bgcolor: '#ffffff',
                p: '8px 12px',
                m: 1,
                borderRadius: '8px',
                '::-webkit-scrollbar': {
                  width: '5px',
                },
                '::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#101936',
                  borderRadius: '6px',
                },
              }}
            >
              <Typography sx={{ textAlign: 'justify' }}>
                <Markdown
                  components={{
                    h2: ({ node, ...props }) => {
                      const headingText = props.children;
                      const headingId = generateId(headingText);
                      return (
                        <Typography
                          id={headingId}
                          variant="h6"
                          sx={{
                            fontWeight: 'bold',
                            color: '#303f9f',
                            borderBottom: '1px solid #e6e6e6',
                            paddingBottom: '8px',
                            marginBottom: '12px',
                            marginTop: '8px',
                          }}
                          {...props}
                        />
                      );
                    },
                    h3: ({ node, ...props }) => (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 'bold',
                          marginBottom: '4px',
                        }}
                        {...props}
                      />
                    ),
                    h4: ({ node, ...props }) => (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: 'bold',
                          marginBottom: '6px',
                          marginTop: '6px',
                        }}
                        {...props}
                      />
                    ),
                    p: ({ node, ...props }) => (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          marginBottom: '6px',
                        }}
                        {...props}
                      />
                    ),
                    ol: ({ node, ...props }) => (
                      <Box
                        component="ol"
                        sx={{
                          fontSize: '14px',
                          marginBottom: '6px',
                          paddingLeft: '20px',
                          listStyleType: 'decimal',
                        }}
                        {...props}
                      />
                    ),
                    ul: ({ node, ...props }) => (
                      <Box
                        component="ul"
                        sx={{
                          fontSize: '14px',
                          marginBottom: '6px',
                          paddingLeft: '20px',
                          listStyleType: 'disc',
                        }}
                        {...props}
                      />
                    ),
                    li: ({ node, ...props }) => (
                      <Typography
                        component="li"
                        sx={{
                          fontSize: '14px',
                          marginBottom: '4px',
                        }}
                        {...props}
                      />
                    ),
                  }}
                >
                  {additionalCaseDetails?.generatedCaseDetails}
                </Markdown>
              </Typography>
            </Box>
          )}
        </React.Fragment>
      )}
      {(selectedSection === 'Case Summary' ||
        selectedSection === 'case-information' ||
        selectedSection === 'legal-provisions' ||
        selectedSection === 'case-summary' ||
        selectedSection === `courts-findings` ||
        selectedSection === 'case-law-references') && (
        <React.Fragment>
          {loadingAdditionalDetails ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '24px',
                width: '100%',
              }}
            >
              <CircularProgress
                size={20}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                overflowY: 'auto',
                flexShrink: 0,
                bgcolor: '#ffffff',
                p: '8px 12px',
                m: 1,
                borderRadius: '8px',
                '::-webkit-scrollbar': {
                  width: '5px',
                },
                '::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                },
                '::-webkit-scrollbar-thumb': {
                  background: '#101936',
                  borderRadius: '6px',
                },
              }}
            >
              {renderMarkdownContent(additionalCaseDetails?.generatedHeadNotes)}
            </Box>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default CaseDetail;
