// Define action types for statute search
const STATUTE_SEARCH_REQUEST = 'STATUTE_SEARCH_REQUEST';
const STATUTE_SEARCH_SUCCESS = 'STATUTE_SEARCH_SUCCESS';
const STATUTE_SEARCH_FAILURE = 'STATUTE_SEARCH_FAILURE';
const STATUTE_SEARCH_RESET = 'STATUTE_SEARCH_RESET';
const SET_STATUTE_TITLE = 'SET_STATUTE_TITLE';
const RESET_STATUTE_TITLE = 'RESET_STATUTE_TITLE';
const STATUTE_SECTIONS_REQUEST = 'STATUTE_SECTIONS_REQUEST';
const STATUTE_SECTIONS_SUCCESS = 'STATUTE_SECTIONS_SUCCESS';
const STATUTE_SECTIONS_FAILURE = 'STATUTE_SECTIONS_FAILURE';
const STATUTE_SECTIONS_RESET = 'STATUTE_SECTIONS_RESET';

// Initial state
const initialState = {
  loading: false,
  results: [],
  error: null,
};

// Statute search reducer
const statuteSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUTE_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case STATUTE_SEARCH_SUCCESS:
      return {
        loading: false,
        results: action.payload,
        error: null,
      };
    case STATUTE_SEARCH_FAILURE:
      return {
        loading: false,
        results: [],
        error: action.payload,
      };
    case STATUTE_SEARCH_RESET:
      return initialState;
    default:
      return state;
  }
};

export default statuteSearchReducer;

export const statuteSearchFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_IS_STATUTE_SEARCH_FILTER_APPLIED':
      return { isStatuteSearchFilterApplied: action.payload };
    default:
      return state;
  }
};

export const statutePaginationReducer = (
  state = { pageNumber: 1, resultsCount: 10 },
  action
) => {
  switch (action.type) {
    case 'STATUTE_PAGINATION_SUCCESS':
      return action.payload;
    case 'STATUTE_PAGINATION_RESET':
      return {};
    default:
      return state;
  }
};

export const statuteTitleReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_STATUTE_TITLE:
      return { title: action.payload };
    case RESET_STATUTE_TITLE:
      return {};
    default:
      return state;
  }
};

export const statuteSectionsReducer = (state = {}, action) => {
  switch (action.type) {
    case STATUTE_SECTIONS_REQUEST:
      return { loading: true };
    case STATUTE_SECTIONS_SUCCESS:
      return { loading: false, sections: action.payload };
    case STATUTE_SECTIONS_FAILURE:
      return { loading: false, error: action.payload };
    case STATUTE_SECTIONS_RESET:
      return {};
    default:
      return state;
  }
};
