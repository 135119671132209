import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  List,
  ListItemText,
  Typography,
  Collapse,
  ListItemButton,
  Button,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const CaseDetailSidebar = ({ onSectionClick, selectedSection }) => {
  const navigate = useNavigate();
  const [openCaseDetails, setOpenCaseDetails] = useState(false);
  const [openCaseSummary, setOpenCaseSummary] = useState(false);
  const [caseDetailHeadings, setCaseDetailHeadings] = useState([]);

  const caseDetailsFromCosmos = useSelector(
    (state) => state.caseDetailsFromCosmos
  );
  const { loading: loadingAdditionalDetails, caseItem: additionalCaseDetails } =
    caseDetailsFromCosmos;

  useEffect(() => {
    const content = additionalCaseDetails?.generatedCaseDetails;

    if (typeof content === "string") {
      const extractedHeadings = [...content.matchAll(/^##\s(.+)/gm)].map(
        (match) => match[1]
      );
      setCaseDetailHeadings(extractedHeadings);
    }
  }, [additionalCaseDetails]);

  const handleCaseDetailsClick = () => {
    setOpenCaseDetails(!openCaseDetails);
    if (!openCaseDetails) {
      setOpenCaseSummary(false);
    }
    onSectionClick("Case Details");
  };

  const handleCaseSummaryClick = () => {
    setOpenCaseSummary(!openCaseSummary);
    if (!openCaseSummary) {
      setOpenCaseDetails(false);
    }
    onSectionClick("Case Summary");
  };

  const handleOriginalJudgementClick = () => {
    setOpenCaseDetails(false);
    setOpenCaseSummary(false);
    onSectionClick("Original Judgement");
  };

  return (
    <Box
      sx={{
        width: "278px",
        overflowY: "auto",
        height: "calc(100vh - 50px)",
        bgcolor: "white",
        boxSizing: "border-box",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#101936",
          borderRadius: "6px",
        },
      }}
    >
      <Button
        sx={{
          textTransform: "none",
          m: "8px 4px",
          color: "#303f9f",
          fontWeight: "bold",
        }}
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/app/searchResults")}
      >
        Back
      </Button>
      <Typography
        variant="h6"
        sx={{
          padding: "16px 16px 10px 16px",
          borderBottom: "1px solid #e6e6e6",
        }}
      >
        Navigation
      </Typography>
      <List sx={{ paddingTop: 0 }}>
        <ListItemButton
          sx={{
            backgroundColor:
              selectedSection === "Original Judgement" ? "#f5f5f5" : "",
            borderLeft:
              selectedSection === "Original Judgement"
                ? "2px solid #303f9f"
                : "",
          }}
          onClick={handleOriginalJudgementClick}
        >
          <ListItemText
            primary={
              <Typography sx={{ fontWeight: "bold", color: "#303f9f" }}>
                Original Judgement
              </Typography>
            }
          />
        </ListItemButton>
        <ListItemButton
          sx={{
            backgroundColor:
              selectedSection === "Case Details" ? "#f5f5f5" : "",
            borderLeft:
              selectedSection === "Case Details" ? "2px solid #303f9f" : "",
          }}
          onClick={handleCaseDetailsClick}
        >
          <ListItemText
            primary={
              <Typography sx={{ fontWeight: "bold", color: "#303f9f" }}>
                Case Details
              </Typography>
            }
          />
          {openCaseDetails ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openCaseDetails} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {caseDetailHeadings.map((heading, index) => (
              <ListItemButton
                key={index}
                sx={{
                  pl: 3.5,
                  pt: 0,
                  pb: 0,
                  backgroundColor:
                    selectedSection === heading.toLowerCase() ? "#f5f5f5" : "",
                  borderLeft:
                    selectedSection === heading.toLowerCase()
                      ? "2px solid #303f9f"
                      : "",
                }}
                onClick={() =>
                  onSectionClick(
                    heading
                      .trim()
                      .toLowerCase()
                      .replace(/['\s]+/g, "-")
                  )
                }
              >
                <ListItemText secondary={heading} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
        {/* {openCaseDetails ? <ExpandLess /> : <ExpandMore />} */}
        {/* </ListItemButton> */}
        {/* <Collapse in={openCaseDetails} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{
                pl: 3,
                backgroundColor:
                  selectedSection === 'case-identifiers' ? '#f5f5f5' : '',
                borderLeft:
                  selectedSection === 'case-identifiers'
                    ? '2px solid #303f9f'
                    : '',
              }}
              onClick={() => onSectionClick('case-identifiers')}
            >
              <ListItemText primary="Case Identifiers" />
            </ListItemButton>
            <ListItemButton
              sx={{
                pl: 3,
                backgroundColor:
                  selectedSection === 'case-information' ? '#f5f5f5' : '',
                borderLeft:
                  selectedSection === 'case-information'
                    ? '2px solid #303f9f'
                    : '',
              }}
              onClick={() => onSectionClick('case-information')}
            >
              <ListItemText primary="Case Information" />
            </ListItemButton>
          </List>
        </Collapse> */}
        <ListItemButton onClick={handleCaseSummaryClick}>
          <ListItemText
            primary={
              <Typography sx={{ fontWeight: "bold", color: "#303f9f" }}>
                Case Summary
              </Typography>
            }
          />
          {openCaseSummary ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openCaseSummary} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{
                pl: 3,
                pt: 0,
                pb: 0,
                backgroundColor:
                  selectedSection === "case-information" ? "#f5f5f5" : "",
                borderLeft:
                  selectedSection === "case-information"
                    ? "2px solid #303f9f"
                    : "",
              }}
              onClick={() => onSectionClick("case-information")}
            >
              <ListItemText secondary="Case Information" />
            </ListItemButton>
            <ListItemButton
              sx={{
                pl: 3,
                pt: 0,
                pb: 0,
                backgroundColor:
                  selectedSection === "legal-provisions" ? "#f5f5f5" : "",
                borderLeft:
                  selectedSection === "legal-provisions"
                    ? "2px solid #303f9f"
                    : "",
              }}
              onClick={() => onSectionClick("legal-provisions")}
            >
              <ListItemText secondary="Legal Provisions" />
            </ListItemButton>
            <ListItemButton
              sx={{
                pl: 3,
                pt: 0,
                pb: 0,
                backgroundColor:
                  selectedSection === "case-summary" ? "#f5f5f5" : "",
                borderLeft:
                  selectedSection === "case-summary" ? "2px solid #303f9f" : "",
              }}
              onClick={() => onSectionClick("case-summary")}
            >
              <ListItemText sx={{ color: "black" }} secondary="Summary" />
            </ListItemButton>
            <ListItemButton
              sx={{
                pl: 3,
                pt: 0,
                pb: 0,
                backgroundColor:
                  selectedSection === `courts-findings` ? "#f5f5f5" : "",
                borderLeft:
                  selectedSection === "courts-findings"
                    ? "2px solid #303f9f"
                    : "",
              }}
              onClick={() => onSectionClick(`courts-findings`)}
            >
              <ListItemText secondary="Court's Findings" />
            </ListItemButton>
            <ListItemButton
              sx={{
                pl: 3,
                pt: 0,
                pb: 0,
                backgroundColor:
                  selectedSection === "case-law-references" ? "#f5f5f5" : "",
                borderLeft:
                  selectedSection === "case-law-references"
                    ? "2px solid #303f9f"
                    : "",
              }}
              onClick={() => onSectionClick("case-law-references")}
            >
              <ListItemText secondary="Case Law References" />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </Box>
  );
};

export default CaseDetailSidebar;
